import React from 'react';
import cx from 'classnames';

import styles from './CodeEditorLeftNav.module.scss';

import CodeEditorIcons, { CodeEditorIconsProps } from '../../molecules/CodeEditorIcons';
import SettingsContent, { SettingsContentProps } from '../SettingsContent';
import TagsContent, { TagsContentProps } from '../TagsContent';

export type CodeEditorLeftNavStateType = 'Collapsed' | 'Settings' | 'Tags';

const defaultProps = {
  state: 'Tags' as CodeEditorLeftNavStateType,
  codeEditorIcons: {
    settingsIcon: {
      asset: 'Settings',
    },
    tagIcon: {
      asset: 'Pricetag',
    },
  } as CodeEditorIconsProps,
  settingsContent: {
    title: 'Settings',
    templateNameField: {
      state: 'Default',
      label: 'Template name',
      textBox: {
        style: 'Placeholder',
        htmlTag: 'input',
        textBoxPlaceholder: 'Placeholder text',
      },
    },
    emailSubjectField: {
      state: 'Default',
      label: 'Email subject',
      textBox: {
        style: 'Placeholder',
        htmlTag: 'input',
        textBoxPlaceholder: 'Placeholder text',
      },
    },
    senderNameField: {
      state: 'Default',
      label: 'Sender name',
      textBox: {
        style: 'Placeholder',
        htmlTag: 'input',
        textBoxPlaceholder: 'Placeholder text',
      },
    },
    senderEmailField: {
      state: 'Default',
      label: 'Sender email',
      textBox: {
        style: 'Placeholder',
        htmlTag: 'input',
        textBoxPlaceholder: 'Placeholder text',
      },
    },
    replyEmailField: {
      state: 'Default',
      label: 'Reply email',
      textBox: {
        style: 'Placeholder',
        htmlTag: 'input',
        textBoxPlaceholder: 'Placeholder text',
      },
    },
    dividers: {
      asset: 'HorizontalBasic300',
    },
    buttonGroup: {
      primaryButton: {
        style: 'Brand',
        htmlTag: 'button',
        content: 'Brand',
      },
      secondaryButton: {
        style: 'Basic',
        htmlTag: 'button',
        content: 'Basic',
      },
    },
  } as SettingsContentProps,
  tagsContent: {
    title: 'Tags',
    tagNameField: {
      state: 'Default',
      label: 'Search',
      textBox: {
        style: 'Placeholder',
        htmlTag: 'input',
        textBoxPlaceholder: 'Placeholder text',
      },
    },
    tagList: {
      tagItems: [
          {
            value: '{{tagname}}',
            icon: {
              asset: 'Copy',
            },
          },
          {
            value: '{{tagname}}',
            icon: {
              asset: 'Copy',
            },
          },
          {
            value: '{{tagname}}',
            icon: {
              asset: 'Copy',
            },
          },
          {
            value: '{{tagname}}',
            icon: {
              asset: 'Copy',
            },
          },
          {
            value: '{{tagname}}',
            icon: {
              asset: 'Copy',
            },
          },
          {
            value: '{{tagname}}',
            icon: {
              asset: 'Copy',
            },
          },
          {
            value: '{{tagname}}',
            icon: {
              asset: 'Copy',
            },
          },
          {
            value: '{{tagname}}',
            icon: {
              asset: 'Copy',
            },
          },
          {
            value: '{{tagname}}',
            icon: {
              asset: 'Copy',
            },
          },
          {
            value: '{{tagname}}',
            icon: {
              asset: 'Copy',
            },
          },
      ],
      title: 'Identity tags',
    },
  } as TagsContentProps,
};

export type CodeEditorLeftNavProps = {
  state?: CodeEditorLeftNavStateType;
  codeEditorIcons?: CodeEditorIconsProps;
  className?: string;
  settingsContent?: SettingsContentProps;
  tagsContent?: TagsContentProps;
};

const CodeEditorLeftNav: React.FC<CodeEditorLeftNavProps> = ({
  state,
  codeEditorIcons,
  className,
  settingsContent,
  tagsContent,
}) => {

  const codeEditorIconsView = (
    <CodeEditorIcons
      className={styles.codeEditorIcons}
      {...codeEditorIcons} />
  );
  
  let contentView;
  
  switch (state) {
    case 'Collapsed':
      break;
    case 'Settings':
      contentView = (
        <div className={styles.content}>
          <SettingsContent
            className={styles.settingsContent}
            {...settingsContent} />
        </div>
      );
      break;
    case 'Tags':
      contentView = (
        <div className={styles.content}>
          <TagsContent
            className={styles.tagsContent}
            {...tagsContent} />
        </div>
      );
      break;
  }

  return (
    <div className={cx(styles.codeEditorLeftNav, className)}>
      {codeEditorIconsView}
      {contentView}
    </div>
  );
};

CodeEditorLeftNav.defaultProps = defaultProps;

export default CodeEditorLeftNav;
