import React from 'react';
import cx from 'classnames';

import styles from './CodeEditor.module.scss';

import CodeEditorSubheader, { CodeEditorSubheaderProps } from '../../organisms/CodeEditorSubheader';
import CodeEditorArea, { CodeEditorAreaProps } from '../../organisms/CodeEditorArea';

const defaultProps = {
  codeEditorSubheader: {
    templateName: 'Template name',
  } as CodeEditorSubheaderProps,
  codeEditorArea: {
    codeAreaPlaceholder: 'Code goes here',
  } as CodeEditorAreaProps,
};

export type CodeEditorProps = {
  codeEditorSubheader?: CodeEditorSubheaderProps;
  codeEditorArea?: CodeEditorAreaProps;
  className?: string;
};

const CodeEditor: React.FC<CodeEditorProps> = ({
  codeEditorSubheader,
  codeEditorArea,
  className,
}) => {
  return (
    <div className={cx(styles.codeEditor, className)}>
      <CodeEditorSubheader
        className={styles.codeEditorSubheader}
        {...codeEditorSubheader} />
      <CodeEditorArea
        className={styles.codeEditorArea}
        {...codeEditorArea} />
    </div>
  );
};

CodeEditor.defaultProps = defaultProps;

export default CodeEditor;
