import { useCallback } from 'react';
import useGet from '../../lib/api/useGet';
import useUpdate from '../../lib/api/useUpdate';
import { getTemplateHtml, updateTemplateHtml } from './api';

export type UseTemplateHtmlResult = [
  {
    loading: boolean;
    error: undefined | Error;
    data: string | null;
    refetch: () => void;
  },
  (payload: string) => Promise<string | undefined>
];

const useTemplateHtml = (id: number | string): UseTemplateHtmlResult => {
  const get = useCallback((): Promise<string> => getTemplateHtml(id), [id]);
  const {
    loading: getLoading,
    error: getError,
    data,
    refetch,
  } = useGet(get);

  const [
    { loading: updateLoading, error: updateError },
    update,
  ] = useUpdate<void, string>(
    (payload: string) => updateTemplateHtml(Number(id), payload)
  );

  const handleUpdate = async (payload: string): Promise<string | undefined> => {
    const { error } = await update(payload);
    refetch();
    return error?.message;
  };

  return [
    {
      data, loading: getLoading || updateLoading, error: getError || updateError, refetch,
    },
    handleUpdate,
  ];
};

export default useTemplateHtml;
