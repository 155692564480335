import React from 'react';
import cx from 'classnames';

import styles from './ConfirmationModal.module.scss';

import Icon, { IconProps } from '../../atoms/Icon';
import ButtonGroup, { ButtonGroupProps } from '../../molecules/ButtonGroup';

const defaultProps = {
  icon: {
    asset: 'Close',
  } as IconProps,
  modalBody: 'Modal Title',
  modalTitle: 'Modal body',
  buttonGroup: {
    primaryButton: {
      style: 'Brand',
      htmlTag: 'button',
      content: 'Brand',
    },
    secondaryButton: {
      style: 'Basic',
      htmlTag: 'button',
      content: 'Basic',
    },
  } as ButtonGroupProps,
};

export type ConfirmationModalProps = {
  icon?: IconProps;
  isModalOpen: boolean;
  closeModal: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  modalBody?: string;
  modalTitle?: string;
  buttonGroup?: ButtonGroupProps;
  className?: string;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  icon,
  modalBody,
  modalTitle,
  buttonGroup,
  className,
  isModalOpen,
  closeModal,
}) => {
  if (!isModalOpen) {
    return null;
  }
  const closeHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  }

  return (
    <div className={styles.confirmationModalContainer} onClick={closeHandler}>
      <div className={cx(styles.confirmationModal, className)}>
        <div className={styles.content}>
          <div className={styles.closeFrame}>
            <button onClick={closeModal}>
              <Icon
                className={styles.icon}
                {...icon} />
            </button>
          </div>
          <div className={styles.textContent}>
            <p className={styles.modalTitle}>
              {modalTitle}
            </p>
            <p className={styles.modalBody}>
              {modalBody}
            </p>
          </div>
        </div>
        <ButtonGroup
          className={styles.buttonGroup}
          {...buttonGroup} />
      </div>
    </div>
  );
};

ConfirmationModal.defaultProps = defaultProps;

export default ConfirmationModal;
