import { useCallback } from 'react';
import useDelete from '../../lib/api/useDelete';
import { deletetemplateById } from './api';
import { UseDeleteTemplateResult } from './types';

const useDeleteTemplate = (): UseDeleteTemplateResult => {
  const onDelete = useCallback((templateId: number): Promise<void> => deletetemplateById(templateId), []);
  return useDelete(onDelete);
};

export default useDeleteTemplate;
