import React from 'react';
import cx from 'classnames';

import styles from './Button.module.scss';

export type ButtonStyleType = 'Brand' | 'Basic';
export type ButtonHtmlTagType = 'button';
export type ButtonButtonTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  style: 'Brand' as ButtonStyleType,
  htmlTag: 'button' as ButtonHtmlTagType,
  content: 'Basic',
};

export type ButtonProps = {
  style?: ButtonStyleType;
  htmlTag?: ButtonHtmlTagType;
  buttonType?: ButtonButtonTypeType;
  onButtonClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  content?: string;
  loading?: boolean;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  style,
  htmlTag,
  loading,
  buttonType,
  onButtonClicked,
  content,
  className,
}) => {

  let currentStyle = '';
  switch (style) {
    case 'Brand':
      currentStyle = styles.buttonBrand;
      break;
    case 'Basic':
      currentStyle = styles.buttonBasic;
      break;
    default:
      break;
  }

  return (
    <button
      type={buttonType}
      onClick={onButtonClicked}
      disabled={loading}
      className={cx(currentStyle, className)}>
      <p className={styles.content}>
        {content}
      </p>
    </button>
  );
};

Button.defaultProps = defaultProps;

export default Button;
