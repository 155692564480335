import React, { useState } from 'react';
import { CodeEditorLeftNavProps, CodeEditorLeftNavStateType } from './CodeEditorLeftNav';

const withPresenter = (
  View: React.FC<CodeEditorLeftNavProps>,
): React.FC<Omit<CodeEditorLeftNavProps, 'state'>> => {
  const Presenter: React.FC<Omit<CodeEditorLeftNavProps, 'state'>> = (props) => {
    const [navState, setNavState] = useState<CodeEditorLeftNavStateType>('Collapsed');

    const { codeEditorIcons } = props;

    const toggleNavState = (value: CodeEditorLeftNavStateType): void => {
      setNavState(navState === value ? 'Collapsed' : value);
    };

    const tagAsset: 'PricetagSelected' | 'Pricetag' = navState === 'Tags' ? 'PricetagSelected' : 'Pricetag';
    const settingsAsset: 'SettingsSelected' | 'Settings' = navState === 'Settings' ? 'SettingsSelected' : 'Settings';

    const iconListWithHandler = {
      ...codeEditorIcons,
      tagIcon: {
        ...codeEditorIcons?.tagIcon,
        onClick: (): void => toggleNavState('Tags'),
        asset: tagAsset,
      },
      settingsIcon: {
        ...codeEditorIcons?.settingsIcon,
        onClick: (): void => toggleNavState('Settings'),
        asset: settingsAsset,
      },
    };

    return <View {...props} 
      state={navState} 
      codeEditorIcons={iconListWithHandler} />;
  };

  Presenter.defaultProps = View.defaultProps;

  return Presenter;
};

export default withPresenter;
