import React from 'react';
import cx from 'classnames';
import styles from './TemplateName.module.scss';
import Icon, { IconProps } from '../Icon';
import OptionMenu, { OptionMenuProps } from '../optionMenu';


const defaultProps = {
  serviceName: 'Template name',
  icon: {
    asset: 'MoreVerticalSmall',
  } as IconProps,
  opetionMenu: {
    options: [{
      name: 'Edit',
    }]
  } as OptionMenuProps
};

export type TemplateNameProps = {
  serviceName?: string;
  icon?: IconProps;
  className?: string;
  optionMenu?: OptionMenuProps
};

const TemplateName: React.FC<TemplateNameProps> = ({
  serviceName,
  icon,
  className,
  optionMenu
}) => {
  return (
    <div className={cx(styles.templateName, className)}>
      <p className={styles.serviceName}>
        {serviceName}
      </p>

      <OptionMenu
        {...optionMenu}
        icon={icon}
      />
    </div>
  );
};

TemplateName.defaultProps = defaultProps;

export default TemplateName;
