import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { TemplateBlockProps } from './TemplateBlock';
import useSchema from '../../../modules/schema/useSchema';
import { TemplateBlockPresenterProps } from './TemplateBlock.presenter';
import useCreateTemplate from '../../../modules/templates/useCreateTemplate';
import { AuthContext } from '../../../modules/auth/AuthContext'

const withInteractor = (
  Presenter: React.FC<TemplateBlockPresenterProps>,
): React.FC<TemplateBlockProps> => {
  const Interactor: React.FC<TemplateBlockProps> = (props) => {
    const { appDetailsState: { data } } = useContext(AuthContext)
    const { schemaId } = useParams<{ schemaId: string }>();
    const schemaState = useSchema(schemaId);
    const [
      templateState,
      createTemplate,
    ] = useCreateTemplate();

    return (
      <Presenter
        {...props}
        schemaState={schemaState}
        templateState={templateState}
        createTemplate={createTemplate}
        appDetails={data}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
