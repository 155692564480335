import React from 'react';
import { UseSchemaResult } from '../../../../modules/schema/useSchema';
import { UseTemplateResult } from '../../../../modules/templates/useTemplate';
import { UseTemplateHtmlResult } from '../../../../modules/templates/useTemplateHtml';
import { EditorContextProps } from './EditorContext';

export type EditorContextPresenterProps = {
  schemaState: UseSchemaResult;
  templateState: UseTemplateResult;
  templateHtmlState: UseTemplateHtmlResult;
};

const withPresenter = (
  View: React.FC<EditorContextProps>,
): React.FC<EditorContextPresenterProps> => {
  const Presenter: React.FC<EditorContextPresenterProps> = (props) => {
    const {
      schemaState,
      templateState: [templateState, updateTemplate],
      templateHtmlState: [templateHtmlState, updateTemplateHtml],
      ...rest
    } = props;
    const value = {
      schemaState,
      templateState,
      updateTemplate,
      templateHtmlState,
      updateTemplateHtml,
    };

    return <View value={value} {...rest} />;
  };

  return Presenter;
};

export default withPresenter;
