import React from 'react';
import cx from 'classnames';

import styles from './CodeEditorIcons.module.scss';

import Icon, { IconProps } from '../../atoms/Icon';

const defaultProps = {
  settingsIcon: {
    asset: 'Settings',
  } as IconProps,
  tagIcon: {
    asset: 'Pricetag',
  } as IconProps,
};

export type CodeEditorIconsProps = {
  settingsIcon?: IconProps;
  tagIcon?: IconProps;
  className?: string;
};

const CodeEditorIcons: React.FC<CodeEditorIconsProps> = ({
  settingsIcon,
  tagIcon,
  className,
}) => {
  return (
    <div className={cx(styles.codeEditorIcons, className)}>
      <Icon
        className={styles.settingsIcon}
        {...settingsIcon} />
      <Icon
        className={styles.tagIcon}
        {...tagIcon} />
    </div>
  );
};

CodeEditorIcons.defaultProps = defaultProps;

export default CodeEditorIcons;
