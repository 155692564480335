import React from 'react';
import cx from 'classnames';

import styles from './MenuItem.module.scss';

import MenuItemBody, { MenuItemBodyProps } from '../../atoms/MenuItemBody';
import NestedMenuItemList, { NestedMenuItemListProps } from '../NestedMenuItemList';

export type MenuItemStateType = 'Collapsed' | 'Expanded';

const defaultProps = {
  state: 'Expanded' as MenuItemStateType,
  menuItemBody: {
    state: 'Expanded',
    htmlTag: 'button',
    icon: {
      asset: 'Star',
    },
    value: {
      value: 'List Item'
    },
    expandIcon: {
      asset: 'ArrowUp',
    },
  } as MenuItemBodyProps,
  nestedMenuItemList: {
    nestedMenuItems: [
      {
        menuItemBody: {
          state: 'Collapsed',
          htmlTag: 'button',
          icon: {
            asset: 'Star',
          },
          value: {
            value: 'List item',
          },
          expandIcon: {
            asset: 'ArrowDown',
          },
        },
      },
      {
        menuItemBody: {
          state: 'Collapsed',
          htmlTag: 'button',
          icon: {
            asset: 'Star',
          },
          value: {
            value: 'List item',
          },
          expandIcon: {
            asset: 'ArrowDown',
          },
        },
      },
      {
        menuItemBody: {
          state: 'Collapsed',
          htmlTag: 'button',
          icon: {
            asset: 'Star',
          },
          value: {
            value: 'List item',
          },
          expandIcon: {
            asset: 'ArrowDown',
          },
        },
      },
    ],
  } as NestedMenuItemListProps,
};

export type MenuItemProps = {
  state?: MenuItemStateType;
  menuItemBody?: MenuItemBodyProps;
  className?: string;
  nestedMenuItemList?: NestedMenuItemListProps;
};

const MenuItem: React.FC<MenuItemProps> = ({
  state,
  menuItemBody,
  className,
  nestedMenuItemList,
}) => {

  const menuItemBodyView = (
    <MenuItemBody
      className={styles.menuItemBody}
      {...menuItemBody} />
  );

  let nestedMenuItemListView;

  switch (state) {
    case 'Collapsed':
      break;
    case 'Expanded':
      nestedMenuItemListView = (
        <NestedMenuItemList
          className={styles.nestedMenuItemList}
          {...nestedMenuItemList} />
      );
      break;
  }

  return (
    <div className={cx(styles.menuItem, className)}>
      {menuItemBodyView}
      {nestedMenuItemListView}
    </div>
  );
};

MenuItem.defaultProps = defaultProps;

export default MenuItem;
