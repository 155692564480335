import React from 'react';
import cx from 'classnames';

import styles from './UserIcon.module.scss';

import ProfileAsset from '../../../resources/icons/Profile.svg';

export type UserIconStateType = 'Image' | 'Initials';

const defaultProps = {
  state: 'Initials' as UserIconStateType,
  profile: ProfileAsset,
  background: 'BackgroundAsset',
  value: 'TR',
};

export type UserIconProps = {
  state?: UserIconStateType;
  profile?: string;
  profileAlt?: string;
  className?: string;
  background?: string;
  backgroundAlt?: string;
  value?: string;
};

const UserIcon: React.FC<UserIconProps> = ({
  state,
  profile,
  profileAlt,
  className,
  background,
  backgroundAlt,
  value,
}) => {

  let profileView;
  let backgroundView;
  let valueView;
  
  switch (state) {
    case 'Image':
      profileView = (
        <img
          className={styles.profile}
          alt={profileAlt}
          src={profile} />
      );
      break;
    case 'Initials':
      backgroundView = (
        <img
          className={styles.background}
          alt={backgroundAlt}
          src={background} />
      );
      valueView = (
        <p className={styles.value}>
          {value}
        </p>
      );
      break;
  }

  return (
    <div className={cx(styles.userIcon, className)}>
      {profileView}
      {backgroundView}
      {valueView}
    </div>
  );
};

UserIcon.defaultProps = defaultProps;

export default UserIcon;
