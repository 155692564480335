import React from 'react';
import cx from 'classnames';

import styles from './ServiceCard.module.scss';

import ImageContainers, { ImageContainersProps } from '../../atoms/ImageContainers';
import Tag, { TagProps } from '../../atoms/Tag';
import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  imageContainers: {
    style: '64px',
  } as ImageContainersProps,
  serviceName: 'Service Name',
  templatesCount: 'No of templates',
  tag: {
    value: 'Value',
  } as TagProps,
  button: {
    style: 'Brand',
    htmlTag: 'button',
    content: 'Brand',
  } as ButtonProps,
};

export type ServiceCardProps = {
  imageContainers?: ImageContainersProps;
  serviceName?: string;
  templatesCount?: string;
  tag?: TagProps;
  button?: ButtonProps;
  className?: string;
};

const ServiceCard: React.FC<ServiceCardProps> = ({
  imageContainers,
  serviceName,
  templatesCount,
  tag,
  button,
  className,
}) => {
  return (
    <div className={cx(styles.serviceCard, className)}>
      <ImageContainers
        className={styles.imageContainers}
        {...imageContainers} />
      <p className={styles.serviceName}>
        {serviceName}
      </p>
      <p className={styles.templatesCount}>
        {templatesCount}
      </p>
      <Tag
        className={styles.tag}
        {...tag} />
      <Button        
        className={styles.button}
        {...button} />
    </div>
  );
};

ServiceCard.defaultProps = defaultProps;

export default ServiceCard;
