import React from 'react';
import cx from 'classnames';

import styles from './CodeEditorArea.module.scss';

const defaultProps = {
  codeAreaPlaceholder: 'Code goes here',
};

export type CodeEditorAreaProps = {
  codeAreaValue?: string;
  codeAreaPlaceholder?: string;
  onCodeAreaChanged?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  className?: string;
};

const CodeEditorArea: React.FC<CodeEditorAreaProps> = ({
  codeAreaValue,
  codeAreaPlaceholder,
  onCodeAreaChanged,
  className,
}) => {
  return (
    <div className={cx(styles.codeEditorArea, className)}>
      <textarea
        placeholder={codeAreaPlaceholder}
        value={codeAreaValue}
        onChange={onCodeAreaChanged}
        className={styles.codeArea}/>
    </div>
  );
};

CodeEditorArea.defaultProps = defaultProps;

export default CodeEditorArea;
