import React from 'react';
import cx from 'classnames';

import styles from './TagsContent.module.scss';

import TextField, { TextFieldProps } from '../../molecules/TextField';
import TagList, { TagListProps } from '../TagList';

const defaultProps = {
  title: 'Tags',
  tagNameField: {
    state: 'Default',
    label: 'Search',
    textBox: {
      style: 'Placeholder',
      htmlTag: 'input',
      textBoxPlaceholder: 'Placeholder text',
    },
  } as TextFieldProps,
  tagList: {
    tagItems: [
        {
          value: '{{tagname}}',
          icon: {
            asset: 'Copy',
          },
        },
        {
          value: '{{tagname}}',
          icon: {
            asset: 'Copy',
          },
        },
        {
          value: '{{tagname}}',
          icon: {
            asset: 'Copy',
          },
        },
        {
          value: '{{tagname}}',
          icon: {
            asset: 'Copy',
          },
        },
        {
          value: '{{tagname}}',
          icon: {
            asset: 'Copy',
          },
        },
        {
          value: '{{tagname}}',
          icon: {
            asset: 'Copy',
          },
        },
        {
          value: '{{tagname}}',
          icon: {
            asset: 'Copy',
          },
        },
        {
          value: '{{tagname}}',
          icon: {
            asset: 'Copy',
          },
        },
        {
          value: '{{tagname}}',
          icon: {
            asset: 'Copy',
          },
        },
        {
          value: '{{tagname}}',
          icon: {
            asset: 'Copy',
          },
        },
    ],
    title: 'Identity tags',
  } as TagListProps,
};

export type TagsContentProps = {
  title?: string;
  tagNameField?: TextFieldProps;
  tagList?: TagListProps;
  loading?: boolean;
  error?: string | undefined;
  className?: string;
};

const TagsContent: React.FC<TagsContentProps> = ({
  title,
  tagNameField,
  tagList,
  className,
}) => {
  return (
    <div className={cx(styles.tagsContent, className)}>
      <p className={styles.title}>
        {title}
      </p>
      <TextField
        className={styles.tagNameField}
        {...tagNameField} />
      <TagList
        className={styles.tagList}
        {...tagList} />
    </div>
  );
};

TagsContent.defaultProps = defaultProps;

export default TagsContent;
