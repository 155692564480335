import React from 'react';
import cx from 'classnames';

import styles from './TextField.module.scss';

import TextBox, { TextBoxProps } from '../../atoms/TextBox';

export type TextFieldStateType = 'Default' | 'Error';

const defaultProps = {
  state: 'Error' as TextFieldStateType,
  label: 'Label',
  textBox: {
    style: 'Error',
    htmlTag: 'input',
    textBoxPlaceholder: 'User text',
  } as TextBoxProps,
  errorText: 'Error text',
};

export type TextFieldProps = {
  state?: TextFieldStateType;
  label?: string;
  textBox?: TextBoxProps;
  className?: string;
  errorText?: string;
};

const TextField: React.FC<TextFieldProps> = ({
  state,
  label,
  textBox,
  className,
  errorText,
}) => {

  const labelView = (
    <p className={styles.label}>
      {label}
    </p>
  );
  const textBoxView = (
    <TextBox
      className={styles.textBox}
      {...textBox} />
  );
  
  let errorTextView;
  
  switch (state) {
    case 'Default':
      break;
    case 'Error':
      errorTextView = (
        <p className={styles.errorText}>
          {errorText}
        </p>
      );
      break;
  }

  return (
    <div className={cx(styles.textField, className)}>
      {labelView}
      {textBoxView}
      {errorTextView}
    </div>
  );
};

TextField.defaultProps = defaultProps;

export default TextField;
