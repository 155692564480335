import React from 'react';
import cx from 'classnames';
import { Route, Switch } from 'react-router-dom';
import styles from './MainLayout.module.scss';
import Header, { HeaderProps } from '../../organisms/Header';
import LeftNav, { LeftNavProps } from '../../organisms/LeftNav';
import DesignLibraryPage, { DesignLibraryProps } from '../../pages/DesignLibrary';
import TemplatesPage from '../../pages/TemplatesPage';
import { TemplateProvider } from '../../../modules/templates/TemplateContext';
import { SchemaProvider } from '../../../modules/schema/SchemaContext'
const defaultProps = {
  header: {
    spindlLogo: {
    },
    headerContent: {
      appsIcon: {
        asset: 'MoreApps',
      },
      notificationIcon: {
        asset: 'BellDot',
      },
      userProfile: {
        userIcon: {
          state: 'Image',
        },
        arrowIcon: {
          asset: 'ChevronDown',
        },
      },
    },
  } as HeaderProps,
  leftNav: {
    appInfo: {
      appName: 'App name',
      dividers: {
        asset: 'HorizontalBasic300',
      },
    },
    menuItemList: {
      menuItems: [
        {
          state: 'Collapsed',
          menuItemBody: {
            state: 'Collapsed',
            htmlTag: 'button',
            icon: {
              asset: 'Star',
            },
            value: 'List item',
            expandIcon: {
              asset: 'ArrowDown',
            },
          },
        },
        {
          state: 'Collapsed',
          menuItemBody: {
            state: 'Collapsed',
            htmlTag: 'button',
            icon: {
              asset: 'Star',
            },
            value: 'List item',
            expandIcon: {
              asset: 'ArrowDown',
            },
          },
        },
        {
          state: 'Expanded',
          menuItemBody: {
            state: 'Expanded',
            htmlTag: 'button',
            icon: {
              asset: 'Star',
            },
            value: 'List item',
            expandIcon: {
              asset: 'ArrowUp',
            },
          },
          nestedMenuItemList: {
            nestedMenuItems: [
              {
                menuItemBody: {
                  state: 'Collapsed',
                  htmlTag: 'button',
                  icon: {
                    asset: 'Star',
                  },
                  value: 'List item',
                  expandIcon: {
                    asset: 'ArrowDown',
                  },
                },
              },
              {
                menuItemBody: {
                  state: 'Collapsed',
                  htmlTag: 'button',
                  icon: {
                    asset: 'Star',
                  },
                  value: 'List item',
                  expandIcon: {
                    asset: 'ArrowDown',
                  },
                },
              },
              {
                menuItemBody: {
                  state: 'Collapsed',
                  htmlTag: 'button',
                  icon: {
                    asset: 'Star',
                  },
                  value: 'List item',
                  expandIcon: {
                    asset: 'ArrowDown',
                  },
                },
              },
            ],
          },
        },
      ],
    },
  } as LeftNavProps,
  designLibrary: {
    serviceBlock: {
      title: 'Title',
      serviceCardList: {
        serviceCards: [
          {
            imageContainers: {
              style: '64px',
            },
            serviceName: 'Service Name',
            templatesCount: 'No of templates',
            tag: {
              value: 'Value',
            },
            button: {
              style: 'Brand',
              htmlTag: 'button',
              content: 'Brand',
            },
          },
          {
            imageContainers: {
              style: '64px',
            },
            serviceName: 'Service Name',
            templatesCount: 'No of templates',
            tag: {
              value: 'Value',
            },
            button: {
              style: 'Brand',
              htmlTag: 'button',
              content: 'Brand',
            },
          },
          {
            imageContainers: {
              style: '64px',
            },
            serviceName: 'Service Name',
            templatesCount: 'No of templates',
            tag: {
              value: 'Value',
            },
            button: {
              style: 'Brand',
              htmlTag: 'button',
              content: 'Brand',
            },
          },
        ],
      },
    },
  } as DesignLibraryProps,
};

export type MainLayoutProps = {
  header?: HeaderProps;
  leftNav?: LeftNavProps;
  designLibrary?: DesignLibraryProps;
  className?: string;
};

const MainLayout: React.FC<MainLayoutProps> = ({
  header,
  leftNav,
  designLibrary,
  className,
}) => {
  return (
    <div className={cx(styles.mainLayout, className)}>
      <Header
        className={styles.header}
        {...header} />
      <SchemaProvider>
        <TemplateProvider>
          <div className={styles.body}>
            <LeftNav
              className={styles.leftNav}
              {...leftNav} />
            <div className={styles.content}>
              <Switch>
                <Route exact path='/:appId/:serviceName/:schemaId'>
                  <TemplatesPage />
                </Route>
                <Route exact path='/:appId/:serviceName/'>
                  <DesignLibraryPage className={styles.designLibrary}
                    {...designLibrary}
                  />
                </Route>
              </Switch>
            </div>
          </div>
        </TemplateProvider>
      </SchemaProvider>
    </div>
  );
};

MainLayout.defaultProps = defaultProps;

export default MainLayout;
