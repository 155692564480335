import React from 'react';
import cx from 'classnames';

import styles from './TagList.module.scss';

import TagItem, { TagItemProps } from '../../molecules/TagItem';

const defaultProps = {
  tagItems: [
      {
        value: '{{tagname}}',
        icon: {
          asset: 'Copy',
        },
      },
      {
        value: '{{tagname}}',
        icon: {
          asset: 'Copy',
        },
      },
      {
        value: '{{tagname}}',
        icon: {
          asset: 'Copy',
        },
      },
      {
        value: '{{tagname}}',
        icon: {
          asset: 'Copy',
        },
      },
      {
        value: '{{tagname}}',
        icon: {
          asset: 'Copy',
        },
      },
      {
        value: '{{tagname}}',
        icon: {
          asset: 'Copy',
        },
      },
      {
        value: '{{tagname}}',
        icon: {
          asset: 'Copy',
        },
      },
      {
        value: '{{tagname}}',
        icon: {
          asset: 'Copy',
        },
      },
      {
        value: '{{tagname}}',
        icon: {
          asset: 'Copy',
        },
      },
      {
        value: '{{tagname}}',
        icon: {
          asset: 'Copy',
        },
      },
  ] as TagItemProps[],
  title: 'Identity tags',
};

export type TagListProps = {
  tagItems?: TagItemProps[];
  title?: string;
  className?: string;
};

const TagList: React.FC<TagListProps> = ({
  tagItems,
  title,
  className,
}) => {
  const tagItemArray = tagItems?.map((tagItem) => (
    <TagItem
      key={tagItem.value}
      className={styles.tagItem}
      {...tagItem} />
  ));

  return (
    <div className={cx(styles.tagList, className)}>
      <p className={styles.title}>
        {title}
      </p>
      {tagItemArray}
    </div>
  );
};

TagList.defaultProps = defaultProps;

export default TagList;
