import React, { useContext } from 'react';
import { SettingsContentProps } from '.';
import { Template } from '../../../modules/templates/types';

import { EditorContext } from '../../layouts/EditorLayout/context';
import { SettingsContentPresenterProps } from './SettingsContent.presenter';

const withInteractor = (
  Presenter: React.FC<SettingsContentPresenterProps>,
): React.FC<SettingsContentProps> => {
  const Interactor: React.FC<SettingsContentProps> = (props) => {
    const { templateState: { data: template }, updateTemplate } = useContext(EditorContext);

    return <Presenter 
      {...props} 
      template={template as Template} 
      updateTemplate={updateTemplate} />;
  };

  return Interactor;
};

export default withInteractor;
