import React from 'react';
import cx from 'classnames';

import styles from './TemplatesPage.module.scss';

import TemplateBlock, { TemplateBlockProps } from '../../blocks/TemplateBlock';

export const defaultProps = {
  templateBlock: {
    blockHeader: {
      title: 'Title',
      button: {
        style: 'Brand',
        htmlTag: 'button',
        content: 'Brand',
      },
    },
    templateCardList: {
      templateCards: [
        {
          imageContainers: {
            style: '3x2',
          },
          templateCardContent: {
            templateName: {
              serviceName: 'Template name',
              icon: {
                asset: 'MoreVerticalSmall',
              },
            },
            label1: {
              label: 'Label :',
              value: 'Input',
            },
            label2: {
              label: 'Label :',
              value: 'Input',
            },
          },
        },
        {
          imageContainers: {
            style: '3x2',
          },
          templateCardContent: {
            templateName: {
              serviceName: 'Template name',
              icon: {
                asset: 'MoreVerticalSmall',
              },
            },
            label1: {
              label: 'Label :',
              value: 'Input',
            },
            label2: {
              label: 'Label :',
              value: 'Input',
            },
          },
        },
        {
          imageContainers: {
            style: '3x2',
          },
          templateCardContent: {
            templateName: {
              serviceName: 'Template name',
              icon: {
                asset: 'MoreVerticalSmall',
              },
            },
            label1: {
              label: 'Label :',
              value: 'Input',
            },
            label2: {
              label: 'Label :',
              value: 'Input',
            },
          },
        },
        {
          imageContainers: {
            style: '3x2',
          },
          templateCardContent: {
            templateName: {
              serviceName: 'Template name',
              icon: {
                asset: 'MoreVerticalSmall',
              },
            },
            label1: {
              label: 'Label :',
              value: 'Input',
            },
            label2: {
              label: 'Label :',
              value: 'Input',
            },
          },
        },
      ],
    },
  } as TemplateBlockProps,
};

export type TemplatesPageProps = {
  templateBlock?: TemplateBlockProps;
  className?: string;
};

const TemplatesPage: React.FC<TemplatesPageProps> = ({
  templateBlock,
  className,
}) => {
  return (
    <div className={cx(styles.templatesPage, className)}>
      <TemplateBlock
        className={styles.templateBlock}
        {...templateBlock} />
    </div>
  );
};

TemplatesPage.defaultProps = defaultProps;

export default TemplatesPage;
