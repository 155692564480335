import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Schema } from '../../../modules/schema/types';
import { Tag } from '../../../modules/templates/types';
import { TextFieldProps } from '../../molecules/TextField';
import { TagItemProps } from '../../molecules/TagItem';
import { TagListProps } from '../TagList';
import { TagsContentProps } from './TagsContent';

export type TagsContentPresenterProps = TagsContentProps & {
  schema: Schema;
};

const withPresenter = (View: React.FC<TagsContentProps>): React.FC<TagsContentPresenterProps> => {
  const Presenter: React.FC<TagsContentPresenterProps> = (props) => {
    const [tagNameValue, setTagNameValue] = useState('');
    const { t } = useTranslation();
    const { schema, ...otherProps } = props;

    const filteredTags = schema.variables.filter((tag: Tag) => {
      if (!tagNameValue) {
        return true;
      }
      return tag.variableLabel.toLowerCase().includes(tagNameValue.toLowerCase());
    });

    const tagItems: TagItemProps[] = filteredTags.map((tag: Tag) => ({
      icon: {
        asset: 'Copy',
        state: 'Default',
      },
      value: `{{${tag.variableLabel}}}`,
      state: 'Default',
    }));

    const serviceName = schema?.serviceName?.toUpperCase();

    const tagList: TagListProps = {
      title: t('tags_content.tags.title', { serviceName }),
      tagItems,
    };

    const handleTextBoxChanged = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    ): void => {
      setTagNameValue(event.currentTarget.value);
    };

    const tagNameField: TextFieldProps = {
      state: 'Default',
      label: 'Search',
      textBox: {
        style: 'Default',
        htmlTag: 'input',
        textBoxValue: tagNameValue,
        textBoxPlaceholder: t('tags_content.text_box.placeholder'),
        onTextBoxChanged: handleTextBoxChanged,
      },
    };

    return (
      <View
        {...otherProps}
        title={t('tags_content.title')}
        tagList={tagList}
        tagNameField={tagNameField}
      />
    );
  };

  return Presenter;
};

export default withPresenter;
