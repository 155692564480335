import React from 'react';
import cx from 'classnames';

import styles from './Details.module.scss';

const defaultProps = {
  label: 'Label :',
  value: 'Input',
};

export type DetailsProps = {
  label?: string;
  value?: string;
  className?: string;
};

const Details: React.FC<DetailsProps> = ({
  label,
  value,
  className,
}) => {
  return (
    <div className={cx(styles.details, className)}>
      <p className={styles.label}>
        {label}
      </p>
      <p className={styles.value}>
        {value}
      </p>
    </div>
  );
};

Details.defaultProps = defaultProps;

export default Details;
