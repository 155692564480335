import React, { createContext } from 'react';
import useSchemaList from './useSchemaList';
import { UseSchemaProviderResult } from './types';

export type SchemaContextValue = {
  schemaState: UseSchemaProviderResult
};

const defaultValue = {
  schemaState: {
    loading: false,
    error: undefined,
    data: null,
    refetch: (): void => { },
  }
};

export const SchemaContext = createContext<SchemaContextValue>(defaultValue);

export const SchemaProvider: React.FC<{}> = (props) => {
  const { children } = props;
  const schemaState = useSchemaList();

  return (
    <SchemaContext.Provider value={{ schemaState }}>
      {children}
    </SchemaContext.Provider>
  );
};
