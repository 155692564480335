import React from 'react';
import cx from 'classnames';

import styles from './TemplateCardContent.module.scss';

import TemplateName, { TemplateNameProps } from '../../atoms/TemplateName';
import Details, { DetailsProps } from '../../atoms/Details';

const defaultProps = {
  templateName: {
    serviceName: 'Template name',
    icon: {
      asset: 'MoreVerticalSmall',
    },
  } as TemplateNameProps,
  label1: {
    label: 'Label :',
    value: 'Input',
  } as DetailsProps,
  label2: {
    label: 'Label :',
    value: 'Input',
  } as DetailsProps,
};

export type TemplateCardContentProps = {
  templateName?: TemplateNameProps;
  label1?: DetailsProps;
  label2?: DetailsProps;
  className?: string;
};

const TemplateCardContent: React.FC<TemplateCardContentProps> = ({
  templateName,
  label1,
  label2,
  className,
}) => {
  return (
    <div className={cx(styles.templateCardContent, className)}>
      <TemplateName
        className={styles.templateName}
        {...templateName} />
      <Details
        className={styles.label1}
        {...label1} />
      <Details
        className={styles.label2}
        {...label2} />
    </div>
  );
};

TemplateCardContent.defaultProps = defaultProps;

export default TemplateCardContent;
