import React from 'react';
import { SchemaProvider } from '../../../../modules/schema/types';
import { Template } from '../../../../modules/templates/types';

export type EditorContextValue = {
  schemaState: {
    loading: boolean;
    error: Error | undefined;
    data: SchemaProvider | null;
  };
  templateState: {
    loading: boolean;
    error: Error | undefined;
    data: Template | null;
  };
  updateTemplate: (payload: Template) => Promise<void>;
  templateHtmlState: {
    loading: boolean;
    error: Error | undefined;
    data: string | null;
  };
  updateTemplateHtml: (payload: string) => Promise<string | undefined>;
};

export type EditorContextProps = {
  value: EditorContextValue;
};

const defaultValue = {
  schemaState: {
    loading: false,
    error: undefined,
    data: null,
  },
  templateState: {
    loading: false,
    error: undefined,
    data: null,
  },
  updateTemplate: async (): Promise<void> => { },
  templateHtmlState: {
    loading: false,
    error: undefined,
    data: null,
  },
  updateTemplateHtml: async (): Promise<string | undefined> => undefined,
};

export const EditorContext = React.createContext<EditorContextValue>(defaultValue);

const EditorContextProvider: React.FC<EditorContextProps> = (props) => {
  const { value, children } = props;

  return (
    <EditorContext.Provider value={value}>
      {children}
    </EditorContext.Provider>
  );
};

export default EditorContextProvider;
