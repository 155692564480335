import { useCallback } from 'react';
import useGet from '../../lib/api/useGet';
import { getSchemaList } from './api';
import { SchemaProvider } from './types';

export type UseSchemaResult = {
  loading: boolean;
  error: undefined | Error;
  data: SchemaProvider [] | null;
  refetch: () => void;
};

const useSchemaList = (): UseSchemaResult => {
  const get = useCallback((): Promise<SchemaProvider []> => getSchemaList(), []);
  return useGet(get);
};

export default useSchemaList;
