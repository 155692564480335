import React, { createContext } from 'react';
import useTemplateList from './useTemplateList';
import { UseTemplateResult } from './types';

export type TemplateContextValue = {
  templateState: UseTemplateResult
};

const defaultValue = {
  templateState: {
    loading: false,
    error: undefined,
    data: null,
    refetch: (): void => {},
  }
};

export const TemplateContext = createContext<TemplateContextValue>(defaultValue);

export const TemplateProvider: React.FC<{}> = (props) => {
  const { children } = props;
  const templateState = useTemplateList();
  
  return (
    <TemplateContext.Provider value={{ templateState }}>
      {children}
    </TemplateContext.Provider>
  );
};
