import React, { useContext } from 'react';

import { TagsContentProps } from '.';
import { SchemaProvider } from '../../../modules/schema/types';
import { EditorContext } from '../../layouts/EditorLayout/context';
import { TagsContentPresenterProps } from './TagsContent.presenter';

const withInteractor = (
  Presenter: React.FC<TagsContentPresenterProps>,
): React.FC<TagsContentProps> => {
  const Interactor: React.FC<TagsContentProps> = (props) => {
    const { schemaState: { data } } = useContext(EditorContext);

    const { schema } = data as SchemaProvider;

    return <Presenter 
      {...props} 
      schema={schema} />;
  };

  return Interactor;
};

export default withInteractor;
