import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseTestEmailResult } from '../../../modules/email/useTestEmail';
import { useHistory, useParams } from 'react-router-dom';
import { EditorContextValue } from './context/EditorContext';
import { EditorLayoutProps } from './EditorLayout';
import { ConfirmationModalProps } from '../../organisms/ConfirmationModal';

export type EditorLayoutPresenterProps = EditorLayoutProps & {
  context: EditorContextValue;
  testEmail: UseTestEmailResult
};

enum ErrorModal {
  UploadHtml,
  SaveAndExit,
  Hidden,
}

const withPresenter = (
  View: React.FC<EditorLayoutProps>,
): React.FC<EditorLayoutPresenterProps> => {
  const Presenter: React.FC<EditorLayoutPresenterProps> = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [html, setHTML] = useState<string>('');
    const [file, setFile] = useState<string>('');
    const [isModalOpen, setShowModal] = useState<ErrorModal>(ErrorModal.Hidden);
    const {
      schemaState: { loading: schemaLoading, error: schemaError, data: schemaData },
      templateState: { loading: templateLoading, error: templateError, data: templateData },
      templateHtmlState: { loading: templateHtmlLoading, error: templateHtmlError, data: templateHtmlData },
      updateTemplateHtml,
    } = props.context;
    const [
      { loading: testEmailLoading, error: testEmailError },
      sendTestEmail,
    ] = props.testEmail;
    const {
      appId, serviceName, schemaId, templateId
    } = useParams<{ appId: string, serviceName: string, schemaId: string, templateId: string }>();


    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (templateHtmlData) {
        setHTML(templateHtmlData);
      }
    }, [templateHtmlData]);

    const onUploadHtmlClicked = (): void => {
      fileInputRef.current?.click();
    };

    const onFileSelected = (value: string): void => {
      if (!html) {
        setHTML(value);
      } else {
        setFile(value);
        setShowModal(ErrorModal.UploadHtml);
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };

    const onModalConfirm = (): void => {
      setHTML(file);
      setShowModal(ErrorModal.Hidden);
    };

    const closeModal = (): void => {
      setShowModal(ErrorModal.Hidden);
    };

    const onCodeAreaChanged = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    ): void => {
      setHTML(event.currentTarget.value);
    };

    const onBackButtonClicked = async (): Promise<void> => {
      if (!templateHtmlLoading) {
        const errorMessage = await updateTemplateHtml(html);
        if (errorMessage) {
          setShowModal(ErrorModal.SaveAndExit)
        } else {
          history.push(`/${appId}/${serviceName}/${schemaId}`);
        }
      }
    };

    const onSendTestEmail = (receiver: string): void => {
      sendTestEmail({ emailTemplateId: Number(templateId), receiver, html });
    };

    const confirmationModal: ConfirmationModalProps = isModalOpen === ErrorModal.SaveAndExit ? {
      modalTitle: t('editor.confirmation_modal.something_went_wrong'),
      modalBody: t('editor.confirmation_modal.fail_save'),
      icon: {
        asset: 'Close',
      },
      isModalOpen: true,
      closeModal,
      buttonGroup: {
        primaryButton: {
          content: t('editor.confirmation_modal.retry'),
          onButtonClicked: onBackButtonClicked,
          style: 'Brand',
          htmlTag: 'button',
        },
        secondaryButton: {
          content: t('editor.confirmation_modal.cancel'),
          onButtonClicked: closeModal,
          style: 'Basic',
          htmlTag: 'button',
        },
      },
    } : {
      modalTitle: t('editor.confirmation_modal.caution'),
      modalBody: t('editor.confirmation_modal.replace_warning'),
      icon: {
        asset: 'Close',
      },
      isModalOpen: isModalOpen === ErrorModal.UploadHtml,
      closeModal,
      buttonGroup: {
        primaryButton: {
          content: t('editor.confirmation_modal.continue'),
          onButtonClicked: onModalConfirm,
          style: 'Brand',
          htmlTag: 'button',
        },
        secondaryButton: {
          content: t('editor.confirmation_modal.cancel'),
          onButtonClicked: closeModal,
          style: 'Basic',
          htmlTag: 'button',
        },
      },
    };

    const presenterProps: EditorLayoutProps = {
      loading: (schemaLoading || templateLoading || templateHtmlLoading)
        && !(schemaData && templateData && templateHtmlData),
      error: schemaError || templateError || templateHtmlError,
      codeEditorHeader: {
        backButton: {
          htmlTag: 'button',
          icon: {
            asset: 'ArrowLeft',
          },
          text: 'Save & Exit',
          onHeaderItemClicked: onBackButtonClicked,
        },
        headerItems: {
          uploadHtml: {
            htmlTag: 'button',
            icon: {
              asset: 'CloudUpload',
            },
            onHeaderItemClicked: onUploadHtmlClicked,
            text: t('editor.header.upload_html'),
          },
          dividers: {
            asset: 'VerticalBasic800',
          },
          sendEmail: {
            htmlTag: 'button',
            icon: {
              asset: 'CornerUpRight',
            },
            text: 'Send Test',
          },
          sendTestModalProps: {
            button: {
              loading: testEmailLoading,
              content: t('editor.send_test_modal.button.content'),
            },
            textField: {
              state: testEmailError ? 'Error' : 'Default',
              errorText: testEmailError?.message,
            },
            sendTestEmail: onSendTestEmail,
          }
        },
      },
      codeEditorLeftNav: {
        state: 'Collapsed',
        codeEditorIcons: {
          settingsIcon: {
            asset: 'Settings',
          },
          tagIcon: {
            asset: 'Pricetag',
          },
        },
      },
      codeEditor: {
        codeEditorSubheader: {
          templateName: templateData?.name,
        },
        codeEditorArea: {
          codeAreaValue: html,
          codeAreaPlaceholder: 'Code goes here',
          onCodeAreaChanged,
        },
      },
      filePicker: {
        fileInputRef,
        onFileSelected,
      },
      confirmationModal,
    };

    return (
      <View
        {...props}
        {...presenterProps}
      />
    );
  };

  return Presenter;
};

export default withPresenter;
