import React from 'react';
import cx from 'classnames';

import styles from './CodeEditorHeader.module.scss';

import HeaderItem, { HeaderItemProps } from '../../atoms/HeaderItem';
import HeaderItems, { HeaderItemsProps } from '../../molecules/HeaderItems';

const defaultProps = {
  backButton: {
    htmlTag: 'button',
    icon: {
      asset: 'Star',
    },
    text: 'Text',
  } as HeaderItemProps,
  headerItems: {
    uploadHtml: {
      htmlTag: 'button',
      icon: {
        asset: 'Star',
      },
      text: 'Text',
    },
    dividers: {
      asset: 'VerticalBasic800',
    },
    sendEmail: {
      htmlTag: 'button',
      icon: {
        asset: 'Star',
      },
      text: 'Text',
    },
  } as HeaderItemsProps,
};

export type CodeEditorHeaderProps = {
  backButton?: HeaderItemProps;
  headerItems?: HeaderItemsProps;
  className?: string;
};

const CodeEditorHeader: React.FC<CodeEditorHeaderProps> = ({
  backButton,
  headerItems,
  className,
}) => {
  return (
    <div className={cx(styles.codeEditorHeader, className)}>
      <HeaderItem
        className={styles.backButton}
        {...backButton} />
      <HeaderItems
        className={styles.headerItems}
        {...headerItems} />
    </div>
  );
};

CodeEditorHeader.defaultProps = defaultProps;

export default CodeEditorHeader;
