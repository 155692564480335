import React, { useState } from 'react';
import { LeftNavProps } from './LeftNav';
import { SchemaProvider } from '../../../modules/schema/types';
import { AppInfoProps } from '../../molecules/AppInfo';
import { MenuItemListProps } from '../MenuItemList';
import { AppDetails } from '../../../modules/auth/types'
import { Template } from '../../../modules/templates/types'
import { NestedMenuItemProps } from '../../molecules/NestedMenuItem';
import { useHistory, useParams } from 'react-router-dom';
import { MenuItemProps } from '../../molecules/MenuItem';

export type LeftNavOrganismsPresenterProps = LeftNavProps & {
  schemas: SchemaProvider[] | null;
  appDetails: AppDetails | null;
  templates: Template[] | null;
};
const rootList: MenuItemProps[] = [
  {
    state: 'Collapsed',
    menuItemBody: {
      state: 'Collapsed',
      htmlTag: 'button',
      icon: {
        asset: 'Star',
      },
      value: {
        value: 'Design Library',
      },
      expandIcon: {
        asset: 'ArrowDown',
      },
    },
  }
]

const withPresenter = (
  View: React.FC<LeftNavProps>,
): React.FC<LeftNavOrganismsPresenterProps> => {
  const Presenter: React.FC<LeftNavOrganismsPresenterProps> = (props) => {
    const {
      schemas,
      appDetails,
      templates,
      className,
    } = props;
    const history = useHistory();
    const [statesArray, setStatesArray] = useState<number[]>([])
    const {
      appId, serviceName
    } = useParams<{ appId: string, serviceName: string }>();
    const toggleState = (index: number) => {
      if (statesArray.includes(index)) {
        setStatesArray(statesArray.filter(arrayItem => arrayItem !== index))
      } else {
        setStatesArray([...statesArray, index])
      }
    }

    const menuList = rootList?.map((item, index) => {
      let nestedMenuItemList: NestedMenuItemProps[] = []
      schemas?.map(serviceObject => (
        nestedMenuItemList.push({
          menuItemBody: {
            state: 'Collapsed',
            htmlTag: 'button',
            icon: {
              asset: 'Star',
            },
            onMenuItemBodyClicked: () => history.push(`/${appId}/${serviceName}/${serviceObject.id}`),
            value: {
              value: serviceObject.name,
            },
            expandIcon: {
              asset: 'ArrowRight',
            },
          },
        })
      ))
      return {
        state: statesArray.includes(index) ? 'Expanded' : 'Collapsed',
        menuItemBody: {          
          state: statesArray.includes(index) ? 'Expanded' : 'Collapsed',
          expandIcon: {
            asset: statesArray.includes(index) ? 'ArrowUp' : 'ArrowDown',
            onClick: () => toggleState(index),
          },
          value: {
            value: item?.menuItemBody?.value?.value,
            onClick: () => history.push(`/${appId}/${serviceName}`)
          },
          icon: {
            asset: 'Grid',
          }
        },
        nestedMenuItemList: {
          nestedMenuItems: nestedMenuItemList
        }
      }
    })

    const defaultProps = {
      appInfo: {
        appName: appDetails?.name,
        dividers: {
          asset: 'HorizontalBasic300',
        },
      } as AppInfoProps,
      menuItemList: {
        menuItems: menuList ? menuList : [],
      } as MenuItemListProps,
    };

    const LeftNavOrganismsProps: LeftNavProps = defaultProps

    return <View
      {...LeftNavOrganismsProps}
      className={className}
    />;
  };
  return Presenter;
};
export default withPresenter;