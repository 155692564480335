import React from 'react';
import cx from 'classnames';

import { Spinner } from 'react-bootstrap';
import styles from './EditorLayout.module.scss';

import CodeEditorHeader, { CodeEditorHeaderProps } from '../../organisms/CodeEditorHeader';
import CodeEditorLeftNav, { CodeEditorLeftNavProps } from '../../organisms/CodeEditorLeftNav';
import CodeEditor, { CodeEditorProps } from '../../pages/CodeEditor';
import FilePicker, { FilePickerProps } from '../../molecules/FilePicker';
import ConfirmationModal, { ConfirmationModalProps } from '../../organisms/ConfirmationModal';

const defaultProps = {
  loading: false,
  error: undefined,
  codeEditorHeader: {
    backButton: {
      htmlTag: 'button',
      icon: {
        asset: 'ArrowLeft',
      },
      text: 'Save & Exit',
    },
    headerItems: {
      uploadHtml: {
        htmlTag: 'button',
        icon: {
          asset: 'CloudUpload',
        },
        text: 'Upload HTML',
      },
      dividers: {
        asset: 'VerticalBasic800',
      },
      sendEmail: {
        htmlTag: 'button',
        icon: {
          asset: 'CornerUpRight',
        },
        text: 'Send Test',
      },
    },
  } as CodeEditorHeaderProps,
  codeEditorLeftNav: {
    state: 'Collapsed',
    codeEditorIcons: {
      settingsIcon: {
        asset: 'Settings',
      },
      tagIcon: {
        asset: 'Pricetag',
      },
    },
  } as CodeEditorLeftNavProps,
  codeEditor: {
    codeEditorSubheader: {
      templateName: 'Template name',
    },
    codeEditorArea: {
      codeAreaPlaceholder: 'Code goes here',
    },
  } as CodeEditorProps,
};

export type EditorLayoutProps = {
  codeEditorHeader?: CodeEditorHeaderProps;
  codeEditorLeftNav?: CodeEditorLeftNavProps;
  codeEditor?: CodeEditorProps;
  className?: string;
  filePicker: FilePickerProps;
  confirmationModal: ConfirmationModalProps;
  loading: boolean;
  error: Error | undefined;
};

const EditorLayout: React.FC<EditorLayoutProps> = ({
  codeEditorHeader,
  codeEditorLeftNav,
  codeEditor,
  className,
  filePicker,
  confirmationModal,
  loading,
}) => {
  if (loading) {
    return <Spinner animation='border' />;
  }
  return (
    <div className={cx(styles.editorLayout, className)}>
      <CodeEditorHeader
        className={styles.codeEditorHeader}
        {...codeEditorHeader} />
      <div className={styles.body}>
        <CodeEditorLeftNav
          className={styles.codeEditorLeftNav}
          {...codeEditorLeftNav} />
        <CodeEditor
          className={styles.codeEditor}
          {...codeEditor} />
      </div>
      <FilePicker {...filePicker} />
      <ConfirmationModal {...confirmationModal} />
    </div>
  );
};

EditorLayout.defaultProps = defaultProps;

export default EditorLayout;
