import React from 'react';
import cx from 'classnames';

import styles from './SettingsContent.module.scss';

import TextField, { TextFieldProps } from '../../molecules/TextField';
import Dividers, { DividersProps } from '../../atoms/Dividers';
import ButtonGroup, { ButtonGroupProps } from '../../molecules/ButtonGroup';

const defaultProps = {
  title: 'Settings',
  templateNameField: {
    state: 'Default',
    label: 'Template name',
    textBox: {
      style: 'Placeholder',
      htmlTag: 'input',
      textBoxPlaceholder: 'Placeholder text',
    },
  } as TextFieldProps,
  emailSubjectField: {
    state: 'Default',
    label: 'Email subject',
    textBox: {
      style: 'Placeholder',
      htmlTag: 'input',
      textBoxPlaceholder: 'Placeholder text',
    },
  } as TextFieldProps,
  senderNameField: {
    state: 'Default',
    label: 'Sender name',
    textBox: {
      style: 'Placeholder',
      htmlTag: 'input',
      textBoxPlaceholder: 'Placeholder text',
    },
  } as TextFieldProps,
  senderEmailField: {
    state: 'Default',
    label: 'Sender email',
    textBox: {
      style: 'Placeholder',
      htmlTag: 'input',
      textBoxPlaceholder: 'Placeholder text',
    },
  } as TextFieldProps,
  replyEmailField: {
    state: 'Default',
    label: 'Reply email',
    textBox: {
      style: 'Placeholder',
      htmlTag: 'input',
      textBoxPlaceholder: 'Placeholder text',
    },
  } as TextFieldProps,
  dividers: {
    asset: 'HorizontalBasic300',
  } as DividersProps,
  buttonGroup: {
    primaryButton: {
      style: 'Brand',
      htmlTag: 'button',
      content: 'Brand',
    },
    secondaryButton: {
      style: 'Basic',
      htmlTag: 'button',
      content: 'Basic',
    },
  } as ButtonGroupProps,
};

export type SettingsContentProps = {
  showButtons?: boolean;
  title?: string;
  templateNameField?: TextFieldProps;
  emailSubjectField?: TextFieldProps;
  senderNameField?: TextFieldProps;
  senderEmailField?: TextFieldProps;
  replyEmailField?: TextFieldProps;
  dividers?: DividersProps;
  buttonGroup?: ButtonGroupProps;
  className?: string;
};

const SettingsContent: React.FC<SettingsContentProps> = ({
  showButtons,
  title,
  templateNameField,
  emailSubjectField,
  senderNameField,
  senderEmailField,
  replyEmailField,
  dividers,
  buttonGroup,
  className,
}) => {
  let buttonElements;
  if (showButtons) {
    buttonElements = (
      <>
        <Dividers
          className={styles.dividers}
          {...dividers} />
        <ButtonGroup
          className={styles.buttonGroup}
          {...buttonGroup} />
      </>
    );
  }
  return (
    <div className={cx(styles.settingsContent, className)}>
      <p className={styles.title}>
        {title}
      </p>
      <TextField
        className={styles.templateNameField}
        {...templateNameField} />
      <TextField
        className={styles.emailSubjectField}
        {...emailSubjectField} />
      <TextField
        className={styles.senderNameField}
        {...senderNameField} />
      <TextField
        className={styles.senderEmailField}
        {...senderEmailField} />
      <TextField
        className={styles.replyEmailField}
        {...replyEmailField} />
      {buttonElements}
    </div>
  );
};

SettingsContent.defaultProps = defaultProps;

export default SettingsContent;
