import React from 'react';
import cx from 'classnames';

import styles from './DesignLibrary.module.scss';

import ServiceBlock, { ServiceBlockProps } from '../../blocks/ServiceBlock';

export const defaultProps = {
  serviceBlock: {
    title: 'Title',
    serviceCardList: {
      serviceCards: [
          {
            imageContainers: {
              style: '64px',
            },
            serviceName: 'Service Name',
            templatesCount: 'No of templates',
            tag: {
              value: 'Value',
            },
            button: {
              style: 'Brand',
              htmlTag: 'button',
              content: 'Brand',
            },
          },
          {
            imageContainers: {
              style: '64px',
            },
            serviceName: 'Service Name',
            templatesCount: 'No of templates',
            tag: {
              value: 'Value',
            },
            button: {
              style: 'Brand',
              htmlTag: 'button',
              content: 'Brand',
            },
          },
          {
            imageContainers: {
              style: '64px',
            },
            serviceName: 'Service Name',
            templatesCount: 'No of templates',
            tag: {
              value: 'Value',
            },
            button: {
              style: 'Brand',
              htmlTag: 'button',
              content: 'Brand',
            },
          },
      ],
    },
  } as ServiceBlockProps,
};

export type DesignLibraryProps = {
  serviceBlock?: ServiceBlockProps;
  className?: string;
};

const DesignLibrary: React.FC<DesignLibraryProps> = ({
  serviceBlock,
  className,
}) => {
  return (
    <div className={cx(styles.designLibrary, className)}>
      <ServiceBlock
        className={styles.serviceBlock}
        {...serviceBlock} />
    </div>
  );
};

 DesignLibrary.defaultProps = defaultProps;

export default DesignLibrary;
