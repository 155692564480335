import React from 'react';
import cx from 'classnames';

import styles from './TemplateCardList.module.scss';

import TemplateCard, { TemplateCardProps } from '../../molecules/TemplateCard';

const defaultProps = {
  templateCards: [
      {
        imageContainers: {
          style: '3x2',
        },
        templateCardContent: {
          templateName: {
            serviceName: 'Template name',
            icon: {
              asset: 'MoreVerticalSmall',
            },
          },
          label1: {
            label: 'Label :',
            value: 'Input',
          },
          label2: {
            label: 'Label :',
            value: 'Input',
          },
        },
      },
      {
        imageContainers: {
          style: '3x2',
        },
        templateCardContent: {
          templateName: {
            serviceName: 'Template name',
            icon: {
              asset: 'MoreVerticalSmall',
            },
          },
          label1: {
            label: 'Label :',
            value: 'Input',
          },
          label2: {
            label: 'Label :',
            value: 'Input',
          },
        },
      },
      {
        imageContainers: {
          style: '3x2',
        },
        templateCardContent: {
          templateName: {
            serviceName: 'Template name',
            icon: {
              asset: 'MoreVerticalSmall',
            },
          },
          label1: {
            label: 'Label :',
            value: 'Input',
          },
          label2: {
            label: 'Label :',
            value: 'Input',
          },
        },
      },
      {
        imageContainers: {
          style: '3x2',
        },
        templateCardContent: {
          templateName: {
            serviceName: 'Template name',
            icon: {
              asset: 'MoreVerticalSmall',
            },
          },
          label1: {
            label: 'Label :',
            value: 'Input',
          },
          label2: {
            label: 'Label :',
            value: 'Input',
          },
        },
      },
  ] as TemplateCardProps[],
};

export type TemplateCardListProps = {
  templateCards?: TemplateCardProps[];
  className?: string;
};

const TemplateCardList: React.FC<TemplateCardListProps> = ({
  templateCards,
  className,
}) => {
  const templateCardArray = templateCards?.map((templateCard) => (
    <TemplateCard
      className={styles.templateCard}
      {...templateCard} />
  ));
  
  return (
    <div className={cx(styles.templateCardList, className)}>
      {templateCardArray}
    </div>
  );
};

TemplateCardList.defaultProps = defaultProps;

export default TemplateCardList;
