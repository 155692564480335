import React from 'react';
import cx from 'classnames';
import styles from '../Icon/Icon.module.scss';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon, { IconProps } from '../Icon';

export type optionsType = {
  name?: string;
  onClick?: () => void
};

const defaultProps = {
  options: [{
    name: 'Edit',
  }] as optionsType[]
};


export type OptionMenuProps = {
  className?: string;
  options?: optionsType[];
  icon?: IconProps
};

const OptionMenu: React.FC<OptionMenuProps> = ({
  className,
  options,
  icon
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        onClick={handleClick}
      >
        <Icon
          className={styles.icon}
          {...icon}
        />
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
            zIndex: 999
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.name} onClick={option.onClick}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>

    </>
  );
};

OptionMenu.defaultProps = defaultProps;

export default OptionMenu;
