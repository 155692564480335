import React from 'react';
import cx from 'classnames';

import styles from './Icon.module.scss';

import ArrowUpAsset from '../../../resources/icons/ArrowUp.svg';
import ArrowDownAsset from '../../../resources/icons/ArrowDown.svg';
import GridAsset from '../../../resources/icons/Grid.svg';
import BarChartAsset from '../../../resources/icons/BarChart.svg';
import StarAsset from '../../../resources/icons/Star.svg';
import PlusAsset from '../../../resources/icons/Plus.svg';
import BellAsset from '../../../resources/icons/Bell.svg';
import BellDotAsset from '../../../resources/icons/BellDot.svg';
import MoreAppsAsset from '../../../resources/icons/MoreApps.svg';
import ChevronDownAsset from '../../../resources/icons/ChevronDown.svg';
import MoreVerticalAsset from '../../../resources/icons/MoreVertical.svg';
import MoreVerticalSmallAsset from '../../../resources/icons/MoreVerticalSmall.svg';
import ArrowLeftAsset from '../../../resources/icons/ArrowLeft.svg';
import ArrowRightAsset from '../../../resources/icons/ArrowRight.svg';
import SettingsAsset from '../../../resources/icons/Settings.svg';
import SettingsSelectedAsset from '../../../resources/icons/SettingsSelected.svg';
import ExpandAsset from '../../../resources/icons/Expand.svg';
import CollapseAsset from '../../../resources/icons/Collapse.svg';
import PricetagAsset from '../../../resources/icons/Pricetag.svg';
import PricetagSelectedAsset from '../../../resources/icons/PricetagSelected.svg';
import CloudUploadAsset from '../../../resources/icons/CloudUpload.svg';
import UndoAsset from '../../../resources/icons/Undo.svg';
import PaperPlaneAsset from '../../../resources/icons/PaperPlane.svg';
import CopyAsset from '../../../resources/icons/Copy.svg';
import CornerUpRightAsset from '../../../resources/icons/CornerUpRight.svg';
import CloseAsset from '../../../resources/icons/Close.svg';
import CloseSelectedAsset from '../../../resources/icons/CloseSelected.svg';

export type IconAssetType = 'ArrowUp' | 'ArrowDown' | 'Grid' | 'BarChart' | 'Star' | 'Plus' | 'Bell' | 'BellDot' | 'MoreApps' | 'ChevronDown' | 'MoreVertical' | 'MoreVerticalSmall' | 'ArrowLeft' | 'ArrowRight' | 'Settings' | 'SettingsSelected' | 'Expand' | 'Collapse' | 'Pricetag' | 'PricetagSelected' | 'CloudUpload' | 'Undo' | 'PaperPlane' | 'Copy' | 'CornerUpRight' | 'Close' | 'CloseSelected';

const defaultProps = {
  asset: 'CloseSelected' as IconAssetType,
};

export type IconProps = {
  asset?: IconAssetType;
  iconAlt?: string;
  className?: string;
  onClick?: () => void;
};

const Icon: React.FC<IconProps> = ({
  asset,
  iconAlt,
  className,
  onClick,
}) => {

  let icon = '';
  switch (asset) {
    case 'ArrowUp':
      icon = ArrowUpAsset;
      break;
    case 'ArrowDown':
      icon = ArrowDownAsset;
      break;
    case 'Grid':
      icon = GridAsset;
      break;
    case 'BarChart':
      icon = BarChartAsset;
      break;
    case 'Star':
      icon = StarAsset;
      break;
    case 'Plus':
      icon = PlusAsset;
      break;
    case 'Bell':
      icon = BellAsset;
      break;
    case 'BellDot':
      icon = BellDotAsset;
      break;
    case 'MoreApps':
      icon = MoreAppsAsset;
      break;
    case 'ChevronDown':
      icon = ChevronDownAsset;
      break;
    case 'MoreVertical':
      icon = MoreVerticalAsset;
      break;
    case 'MoreVerticalSmall':
      icon = MoreVerticalSmallAsset;
      break;
    case 'ArrowLeft':
      icon = ArrowLeftAsset;
      break;
    case 'ArrowRight':
      icon = ArrowRightAsset;
      break;
    case 'Settings':
      icon = SettingsAsset;
      break;
    case 'SettingsSelected':
      icon = SettingsSelectedAsset;
      break;
    case 'Expand':
      icon = ExpandAsset;
      break;
    case 'Collapse':
      icon = CollapseAsset;
      break;
    case 'Pricetag':
      icon = PricetagAsset;
      break;
    case 'PricetagSelected':
      icon = PricetagSelectedAsset;
      break;
    case 'CloudUpload':
      icon = CloudUploadAsset;
      break;
    case 'Undo':
      icon = UndoAsset;
      break;
    case 'PaperPlane':
      icon = PaperPlaneAsset;
      break;
    case 'Copy':
      icon = CopyAsset;
      break;
    case 'CornerUpRight':
      icon = CornerUpRightAsset;
      break;
    case 'Close':
      icon = CloseAsset;
      break;
    case 'CloseSelected':
      icon = CloseSelectedAsset;
      break;
    default:
      break;
  }

  return (
    <img
      onClick={onClick}
      alt={iconAlt}
      src={icon}
      className={cx(styles.icon, className)} />
  );
};

Icon.defaultProps = defaultProps;

export default Icon;