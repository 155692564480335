import React from 'react';
import cx from 'classnames';

import styles from './AppInfo.module.scss';

import Dividers, { DividersProps } from '../../atoms/Dividers';

const defaultProps = {
  appName: 'App name',
  dividers: {
    asset: 'HorizontalBasic300',
  } as DividersProps,
};

export type AppInfoProps = {
  appName?: string;
  dividers?: DividersProps;
  className?: string;
};

const AppInfo: React.FC<AppInfoProps> = ({
  appName,
  dividers,
  className,
}) => {
  return (
    <div className={cx(styles.appInfo, className)}>
      <div className={styles.content}>
        <div className={styles.icon}/>
        <p className={styles.appName}>
          {appName}
        </p>
      </div>
      <Dividers
        className={styles.dividers}
        {...dividers} />
    </div>
  );
};

AppInfo.defaultProps = defaultProps;

export default AppInfo;
