import React from 'react';
import cx from 'classnames';

import styles from './TemplateBlock.module.scss';

import BlockHeader, { BlockHeaderProps } from '../../molecules/BlockHeader';
import TemplateCardList, { TemplateCardListProps } from '../../organisms/TemplateCardList';

const defaultProps = {
  loading: false,
  error: undefined,
  blockHeader: {
    title: 'Title',
    button: {
      style: 'Brand',
      htmlTag: 'button',
      content: 'Brand',
    },
  } as BlockHeaderProps,
  templateCardList: {
    templateCards: [
      {
        imageContainers: {
          style: '3x2',
        },
        templateCardContent: {
          templateName: {
            serviceName: 'Template name',
            icon: {
              asset: 'MoreVerticalSmall',
            },
          },
          label1: {
            label: 'Label :',
            value: 'Input',
          },
          label2: {
            label: 'Label :',
            value: 'Input',
          },
        },
      },
      {
        imageContainers: {
          style: '3x2',
        },
        templateCardContent: {
          templateName: {
            serviceName: 'Template name',
            icon: {
              asset: 'MoreVerticalSmall',
            },
          },
          label1: {
            label: 'Label :',
            value: 'Input',
          },
          label2: {
            label: 'Label :',
            value: 'Input',
          },
        },
      },
      {
        imageContainers: {
          style: '3x2',
        },
        templateCardContent: {
          templateName: {
            serviceName: 'Template name',
            icon: {
              asset: 'MoreVerticalSmall',
            },
          },
          label1: {
            label: 'Label :',
            value: 'Input',
          },
          label2: {
            label: 'Label :',
            value: 'Input',
          },
        },
      },
      {
        imageContainers: {
          style: '3x2',
        },
        templateCardContent: {
          templateName: {
            serviceName: 'Template name',
            icon: {
              asset: 'MoreVerticalSmall',
            },
          },
          label1: {
            label: 'Label :',
            value: 'Input',
          },
          label2: {
            label: 'Label :',
            value: 'Input',
          },
        },
      },
    ],
  } as TemplateCardListProps,
};

export type TemplateBlockProps = {
  loading?: boolean;
  error?: Error | undefined;
  blockHeader?: BlockHeaderProps;
  templateCardList?: TemplateCardListProps;
  className?: string;
};

const TemplateBlock: React.FC<TemplateBlockProps> = ({
  blockHeader,
  templateCardList,
  loading,
  error,
  className,
}) => {
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <div className={cx(styles.templateBlock, className)}>
      <BlockHeader
        className={styles.blockHeader}
        {...blockHeader}
        loading={loading} />
      <TemplateCardList
        className={styles.templateCardList}
        {...templateCardList} />
    </div>
  );
};

TemplateBlock.defaultProps = defaultProps;

export default TemplateBlock;
