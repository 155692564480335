import React from 'react';
import cx from 'classnames';

import styles from './Header.module.scss';

import SpindlLogo, { SpindlLogoProps } from '../../atoms/SpindlLogo';
import HeaderContent, { HeaderContentProps } from '../../molecules/HeaderContent';

const defaultProps = {
  spindlLogo: {
  } as SpindlLogoProps,
  headerContent: {
    appsIcon: {
      asset: 'MoreApps',
    },
    notificationIcon: {
      asset: 'BellDot',
    },
    userProfile: {
      userIcon: {
        state: 'Image',
      },
      arrowIcon: {
        asset: 'ChevronDown',
      },
    },
  } as HeaderContentProps,
};

export type HeaderProps = {
  spindlLogo?: SpindlLogoProps;
  headerContent?: HeaderContentProps;
  className?: string;
};

const Header: React.FC<HeaderProps> = ({
  spindlLogo,
  headerContent,
  className,
}) => {
  return (
    <div className={cx(styles.header, className)}>
      <SpindlLogo
        className={styles.spindlLogo}
        {...spindlLogo} />
      <HeaderContent
        className={styles.headerContent}
        {...headerContent} />
    </div>
  );
};

Header.defaultProps = defaultProps;

export default Header;
