import React from 'react';
import { useParams } from 'react-router-dom';
import useTestEmail from '../../../modules/email/useTestEmail';
import { CodeEditorHeaderProps } from './CodeEditorHeader';
import { CodeEditorHeaderPresenterProps } from './CodeEditorHeader.presenter';

const withInteractor = (
  Presenter: React.FC<CodeEditorHeaderPresenterProps>,
): React.FC<CodeEditorHeaderProps> => {
  const Interactor: React.FC<CodeEditorHeaderProps> = (props) => {
    const { templateId } = useParams<{ templateId: string }>();
    const [state, sendTestEmail] = useTestEmail();

    return (
      <Presenter
        {...props}
        {...state}
        templateId={templateId}
        sendTestEmail={sendTestEmail}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
