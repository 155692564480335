import React from 'react';
import cx from 'classnames';

import styles from './TextBox.module.scss';

export type TextBoxStyleType = 'Default' | 'Focused' | 'Error' | 'Placeholder';
export type TextBoxHtmlTagType = 'input';

const defaultProps = {
  style: 'Placeholder' as TextBoxStyleType,
  htmlTag: 'input' as TextBoxHtmlTagType,
  textBoxPlaceholder: 'Placeholder text',
};

export type TextBoxProps = {
  style?: TextBoxStyleType;
  htmlTag?: TextBoxHtmlTagType;
  textBoxValue?: string;
  textBoxPlaceholder?: string;
  onTextBoxChanged?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  className?: string;
};

const TextBox: React.FC<TextBoxProps> = ({
  style,
  htmlTag,
  textBoxValue,
  textBoxPlaceholder,
  onTextBoxChanged,
  className,
}) => {

  let currentStyle = '';
  switch (style) {
    case 'Default':
      currentStyle = styles.textBoxDefault;
      break;
    case 'Focused':
      currentStyle = styles.textBoxFocused;
      break;
    case 'Error':
      currentStyle = styles.textBoxError;
      break;
    case 'Placeholder':
      currentStyle = styles.textBoxPlaceholder;
      break;
    default:
      break;
  }

  return (
    <input
      placeholder={textBoxPlaceholder}
      value={textBoxValue}
      onChange={onTextBoxChanged}
      className={cx(currentStyle, className)}
    />
  );
};

TextBox.defaultProps = defaultProps;

export default TextBox;
