import axios from 'axios';
import i18next from 'i18next';
import { ServiceErrorResponse } from '../../lib/api/types';
import { getServerUrl, toApiError } from '../../lib/api/utils';
import { Template } from './types';

const GET_TEMPLATE_ERROR_MESSAGE = i18next.t('error.template.default_get');
const UPDATE_TEMPLATE_ERROR_MESSAGE = i18next.t('error.template.default_update');

export type CreateTemplatePayload = { schemaProviderId: number; name: string; createdBy: string | undefined };
export type UpdateTemplatePayload = Omit<Template, 'id' | 'uuid'>;

export const getTemplate = async (id: number | string): Promise<Template> => {
  const { data, status } = await axios.get<Template | ServiceErrorResponse>(`${getServerUrl()}/emailTemplates/${id}`, { withCredentials: true });

  if (status !== 200) {
    throw new Error((data as ServiceErrorResponse).message || GET_TEMPLATE_ERROR_MESSAGE);
  }
  return data as Template;
};
export const getTemplateList = async (): Promise<Template[]> => {
  const { data, status } = await axios.get<Template[] | ServiceErrorResponse>(`${getServerUrl()}/emailTemplates`, { withCredentials: true });

  if (status !== 200) {
    throw new Error((data as ServiceErrorResponse).message || GET_TEMPLATE_ERROR_MESSAGE);
  }
  return data as Template[];
};

export const updateTemplate = async (
  id: number, payload: UpdateTemplatePayload,
): Promise<Template> => {
  try {
    const { data } = await axios.patch<Template | ServiceErrorResponse>(
      `${getServerUrl()}/emailTemplates/${id}`, payload, { withCredentials: true },
    );
    return data as Template;
  } catch (err) {
    throw new Error((err.response.data as ServiceErrorResponse).message || UPDATE_TEMPLATE_ERROR_MESSAGE);
  }
};

export const deletetemplateById = async (templateId: number): Promise<void> => {
  try {
    await axios.delete<void>(`${getServerUrl()}/emailTemplates/${templateId}`, { withCredentials: true });
  } catch (error) {
    throw toApiError(error);
  }
};

export const createTemplate = async (payload: CreateTemplatePayload): Promise<Template> => {
  const { data, status } = await axios.post<Template | ServiceErrorResponse>(
    `${getServerUrl()}/emailTemplates`,
    payload,
    { withCredentials: true },
  );

  if (status !== 200) {
    throw new Error((data as ServiceErrorResponse).message);
  }

  return data as Template;
};

export const getTemplateHtml = async (id: number | string): Promise<string> => {
  const { data, status } = await axios.get<string | ServiceErrorResponse>(
    `${getServerUrl()}/emailTemplates/${id}/files/`, { withCredentials: true },
  );

  if (status !== 200) {
    throw new Error((data as ServiceErrorResponse).message || GET_TEMPLATE_ERROR_MESSAGE);
  }

  return data as string;
};

export const updateTemplateHtml = async (
  id: number, payload: string,
): Promise<void> => {
  try {
    await axios.post<undefined | ServiceErrorResponse>(
      `${getServerUrl()}/emailTemplates/${id}/files`, { file: payload }, { withCredentials: true },
    );
  } catch (err) {
    throw new Error((err.response.data as ServiceErrorResponse).message || UPDATE_TEMPLATE_ERROR_MESSAGE);
  }
};