import React from 'react';
import cx from 'classnames';

import styles from './TemplateCard.module.scss';

import ImageContainers, { ImageContainersProps } from '../../atoms/ImageContainers';
import TemplateCardContent, { TemplateCardContentProps } from '../TemplateCardContent';

const defaultProps = {
  imageContainers: {
    style: '3x2',
  } as ImageContainersProps,
  templateCardContent: {
    templateName: {
      serviceName: 'Template name',
      icon: {
        asset: 'MoreVerticalSmall',
      },
    },
    label1: {
      label: 'Label :',
      value: 'Input',
    },
    label2: {
      label: 'Label :',
      value: 'Input',
    },
  } as TemplateCardContentProps,
};

export type TemplateCardProps = {
  imageContainers?: ImageContainersProps;
  templateCardContent?: TemplateCardContentProps;
  className?: string;
};

const TemplateCard: React.FC<TemplateCardProps> = ({
  imageContainers,
  templateCardContent,
  className,
}) => {
  return (
    <div className={cx(styles.templateCard, className)}>
      <ImageContainers
        className={styles.imageContainers}
        {...imageContainers} />
      <TemplateCardContent
        className={styles.templateCardContent}
        {...templateCardContent} />
    </div>
  );
};

TemplateCard.defaultProps = defaultProps;

export default TemplateCard;
