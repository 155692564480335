import React, { useContext } from 'react';
import { DesignLibraryProps } from './DesignLibrary';
import { DesignLibraryPagePresenterProps } from './DesignLibrary.presenter';
import { TemplateContext } from '../../../modules/templates/TemplateContext';
import { SchemaContext } from '../../../modules/schema/SchemaContext';
const withInteractor = (
  Presenter: React.FC<DesignLibraryPagePresenterProps>,
): React.FC<DesignLibraryProps> => {

  const Interactor: React.FC<DesignLibraryProps> = (props) => {
    const { templateState } = useContext(TemplateContext)
    const { schemaState } = useContext(SchemaContext)
    return (
      <Presenter
        {...props}
        schemas={schemaState.data}
        templates={templateState.data}
      />
    );
  }
  return Interactor;
};
export default withInteractor;
