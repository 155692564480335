import React from 'react';
import { useParams } from 'react-router-dom';
import useSchema from '../../../../modules/schema/useSchema';
import useTemplate from '../../../../modules/templates/useTemplate';
import useTemplateHtml from '../../../../modules/templates/useTemplateHtml';
import { EditorContextPresenterProps } from './EditorContext.presenter';

const withInteractor = (Presenter: React.FC<EditorContextPresenterProps>): React.FC => {
  const Interactor: React.FC = (props) => {
    const { schemaId, templateId } = useParams<{ schemaId: string; templateId: string }>();
    const schemaState = useSchema(schemaId);
    const templateState = useTemplate(templateId);
    const templateHtmlState = useTemplateHtml(templateId);
    return <Presenter
      {...props}
      schemaState={schemaState}
      templateState={templateState}
      templateHtmlState={templateHtmlState} />;
  };

  return Interactor;
};

export default withInteractor;
