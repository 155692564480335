import React from 'react';
import cx from 'classnames';

import styles from './UserProfile.module.scss';

import UserIcon, { UserIconProps } from '../../atoms/UserIcon';
import Icon, { IconProps } from '../../atoms/Icon';

const defaultProps = {
  userIcon: {
    state: 'Image',
  } as UserIconProps,
  arrowIcon: {
    asset: 'ChevronDown',
  } as IconProps,
};

export type UserProfileProps = {
  userIcon?: UserIconProps;
  arrowIcon?: IconProps;
  className?: string;
};

const UserProfile: React.FC<UserProfileProps> = ({
  userIcon,
  arrowIcon,
  className,
}) => {
  return (
    <div className={cx(styles.userProfile, className)}>
      <UserIcon
        className={styles.userIcon}
        {...userIcon} />
      <Icon
        className={styles.arrowIcon}
        {...arrowIcon} />
    </div>
  );
};

UserProfile.defaultProps = defaultProps;

export default UserProfile;
