import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Template } from '../../../modules/templates/types';
import { SettingsContentProps } from './SettingsContent';

export type SettingsContentPresenterProps = SettingsContentProps & {
  template: Template;
  updateTemplate: (payload: Template) => Promise<void>;
};

const isTemplateChanged = (originalTemplate: Template, newTemplate: Template): boolean => {
  return (originalTemplate.senderEmail !== newTemplate.senderEmail)
    || (originalTemplate.replyEmail !== newTemplate.replyEmail)
    || (originalTemplate.name !== newTemplate.name)
    || (originalTemplate.senderName !== newTemplate.senderName)
    || (originalTemplate.emailSubject !== newTemplate.emailSubject);
};

const withPresenter = (
  View: React.FC<SettingsContentProps>,
): React.FC<SettingsContentPresenterProps> => {
  const Presenter: React.FC<SettingsContentPresenterProps> = (props) => {
    const { template: originalTemplate, updateTemplate, ...otherProps } = props;
    const { t } = useTranslation();
    const [template, setTemplate] = useState(originalTemplate);

    const {
      senderEmail, replyEmail, name, senderName, emailSubject,
    } = template;

    const getChangeHandler = (property: keyof Template) => {
      return (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
      ): void => {
        setTemplate({
          ...template,
          [property]: event.currentTarget.value,
        });
      };
    };

    const onSave = (): void => {
      updateTemplate(template);
    };

    const onDiscard = (): void => {
      setTemplate(originalTemplate);
    };

    const viewProps: SettingsContentProps = {
      showButtons: isTemplateChanged(originalTemplate, template),
      senderEmailField: {
        textBox: {
          textBoxValue: senderEmail,
          onTextBoxChanged: getChangeHandler('senderEmail'),
          textBoxPlaceholder: ''
        },
        state: 'Default',
        label: t('template_settings.sender_email'),
      },
      replyEmailField: {
        textBox: {
          textBoxValue: replyEmail,
          onTextBoxChanged: getChangeHandler('replyEmail'),
          textBoxPlaceholder: ''
        },
        state: 'Default',
        label: t('template_settings.reply_email'),
      },
      templateNameField: {
        textBox: {
          textBoxValue: name,
          onTextBoxChanged: getChangeHandler('name'),
          textBoxPlaceholder: ''
        },
        state: 'Default',
        label: t('template_settings.template_name'),
      },
      senderNameField: {
        textBox: {
          textBoxValue: senderName,
          onTextBoxChanged: getChangeHandler('senderName'),
          textBoxPlaceholder: ''
        },
        state: 'Default',
        label: t('template_settings.sender_name'),
      },
      emailSubjectField: {
        textBox: {
          textBoxValue: emailSubject,
          onTextBoxChanged: getChangeHandler('emailSubject'),
          textBoxPlaceholder: ''
        },
        state: 'Default',
        label: t('template_settings.email_subject'),
      },
      dividers: {
        asset: '',
      },
      buttonGroup: {
        primaryButton: {
          style: 'Brand',
          onButtonClicked: onSave,
          content: t('buttons.save'),
        },
        secondaryButton: {
          style: 'Basic',
          onButtonClicked: onDiscard,
          content: t('buttons.discard'),
        },
      },
    };
    return (
      <View 
        {...otherProps}
        {...viewProps} />
    );
  };

  return Presenter;
};

export default withPresenter;
