import axios from 'axios';
import { ServiceErrorResponse } from '../../lib/api/types';
import { getServerUrl } from '../../lib/api/utils';

export type TestEmailPayload = {
  emailTemplateId: number;
  receiver: string;
  html: string;
};

export const sendTestEmail = async (payload: TestEmailPayload): Promise<void> => {
  const { emailTemplateId, receiver, html } = payload;
  try {        
    const { data } = await axios.post<void | ServiceErrorResponse>(
      `${getServerUrl()}/emailTemplates/${emailTemplateId}/action/sendTest`,
      { receiver, html },
      { withCredentials: true },
    );
    return data as void;
  } catch (err) {
    const errorData =JSON.parse((err.response.data as ServiceErrorResponse).toString())
    throw new Error(errorData.message);
  }
};
