import React from 'react';
import cx from 'classnames';

import styles from './ServiceCardList.module.scss';

import ServiceCard, { ServiceCardProps } from '../../molecules/ServiceCard';

const defaultProps = {
  serviceCards: [
      {
        imageContainers: {
          style: '64px',
        },
        serviceName: 'Service Name',
        templatesCount: 'No of templates',
        tag: {
          value: 'Value',
        },
        button: {
          style: 'Brand',
          htmlTag: 'button',
          content: 'Brand',
        },
      },
      {
        imageContainers: {
          style: '64px',
        },
        serviceName: 'Service Name',
        templatesCount: 'No of templates',
        tag: {
          value: 'Value',
        },
        button: {
          style: 'Brand',
          htmlTag: 'button',
          content: 'Brand',
        },
      },
      {
        imageContainers: {
          style: '64px',
        },
        serviceName: 'Service Name',
        templatesCount: 'No of templates',
        tag: {
          value: 'Value',
        },
        button: {
          style: 'Brand',
          htmlTag: 'button',
          content: 'Brand',
        },
      },
  ] as ServiceCardProps[],
};

export type ServiceCardListProps = {
  serviceCards?: ServiceCardProps[];
  className?: string;
};

const ServiceCardList: React.FC<ServiceCardListProps> = ({
  serviceCards,
  className,
}) => {
  const serviceCardArray = serviceCards?.map((serviceCard) => (
    <ServiceCard
      className={styles.serviceCard}
      {...serviceCard} />
  ));
  
  return (
    <div className={cx(styles.serviceCardList, className)}>
      {serviceCardArray}
    </div>
  );
};

ServiceCardList.defaultProps = defaultProps;

export default ServiceCardList;
