import React from 'react';
import cx from 'classnames';

import styles from './SpindlLogo.module.scss';

import LogoAsset from '../../../resources/icons/Logo.svg';

const defaultProps = {
  logo: LogoAsset,
};

export type SpindlLogoProps = {
  logo?: string;
  logoAlt?: string;
  className?: string;
};

const SpindlLogo: React.FC<SpindlLogoProps> = ({
  logo,
  logoAlt,
  className,
}) => {
  return (
    <div className={cx(styles.spindlLogo, className)}>
      <img
        className={styles.logo}
        alt={logoAlt}
        src={logo} />
    </div>
  );
};

SpindlLogo.defaultProps = defaultProps;

export default SpindlLogo;
