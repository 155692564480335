import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CodeEditorHeaderProps } from './CodeEditorHeader';
import { TestEmailPayload } from '../../../modules/email/api';
import { HeaderItemsProps } from '../../molecules/HeaderItems';
import { ApiResponse } from '../../../lib/api/types';

export type CodeEditorHeaderPresenterProps = {
  templateId: string;
  loading: boolean;
  error?: Error;
  sendTestEmail: (payload: TestEmailPayload) => Promise<ApiResponse<void>>;
  headerItems?: HeaderItemsProps;
};

const withPresenter = (
  View: React.FC<CodeEditorHeaderProps>,
): React.FC<CodeEditorHeaderPresenterProps> => {
  const Presenter: React.FC<CodeEditorHeaderPresenterProps> = (props) => {
    const [receiverEmail, setReceiverEmail] = useState('');
    const { headerItems } = props;
    const { t } = useTranslation();

    const handleSendTestEmail = async (): Promise<void> => {
      if (headerItems?.sendTestModalProps?.sendTestEmail) {
        await headerItems.sendTestModalProps.sendTestEmail(receiverEmail);
      }
    };

    const handleTextBoxChanged = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    ): void => {
      setReceiverEmail(event.currentTarget.value);
    };

    const viewProps: CodeEditorHeaderProps = {
      ...props,
      headerItems: {
        ...headerItems,
        sendTestModalProps: {
          button: {
            ...headerItems?.sendTestModalProps?.button,
            style: 'Brand',
            content: t('editor.send_test_modal.button.content'),
            onButtonClicked: handleSendTestEmail,
          },
          textField: {
            ...headerItems?.sendTestModalProps?.textField,
            label: '',
            textBox: {
              htmlTag: 'input',
              textBoxValue: receiverEmail,
              textBoxPlaceholder: '',
              onTextBoxChanged: handleTextBoxChanged,
            },
          },
        },
      },
    };

    return <View {...viewProps} />;
  };

  return Presenter;
};

export default withPresenter;
