import React from 'react';
import cx from 'classnames';

import styles from './Tag.module.scss';

const defaultProps = {
  value: 'Value',
};

export type TagProps = {
  value?: string;
  className?: string;
};

const Tag: React.FC<TagProps> = ({
  value,
  className,
}) => {
  return (
    <div className={cx(styles.tag, className)}>
      <p className={styles.value}>
        {value}
      </p>
    </div>
  );
};

Tag.defaultProps = defaultProps;

export default Tag;
