import React from 'react';
import cx from 'classnames';

import styles from './HeaderContent.module.scss';

import Icon, { IconProps } from '../../atoms/Icon';
import UserProfile, { UserProfileProps } from '../UserProfile';

const defaultProps = {
  appsIcon: {
    asset: 'MoreApps',
  } as IconProps,
  notificationIcon: {
    asset: 'BellDot',
  } as IconProps,
  userProfile: {
    userIcon: {
      state: 'Image',
    },
    arrowIcon: {
      asset: 'ChevronDown',
    },
  } as UserProfileProps,
};

export type HeaderContentProps = {
  appsIcon?: IconProps;
  notificationIcon?: IconProps;
  userProfile?: UserProfileProps;
  className?: string;
};

const HeaderContent: React.FC<HeaderContentProps> = ({
  appsIcon,
  notificationIcon,
  userProfile,
  className,
}) => {
  return (
    <div className={cx(styles.headerContent, className)}>
      <Icon
        className={styles.appsIcon}
        {...appsIcon} />
      <Icon
        className={styles.notificationIcon}
        {...notificationIcon} />
      <UserProfile
        className={styles.userProfile}
        {...userProfile} />
    </div>
  );
};

HeaderContent.defaultProps = defaultProps;

export default HeaderContent;
