import React from 'react';
import cx from 'classnames';
import { Dropdown } from 'react-bootstrap';

import styles from './HeaderItems.module.scss';

import HeaderItem, { HeaderItemProps } from '../../atoms/HeaderItem';
import Dividers, { DividersProps } from '../../atoms/Dividers';
import SendTestModal, { SendTestModalProps } from '../../organisms/SendTestModal';
import CustomToggle from './CustomToggle';

const defaultProps = {
  uploadHtml: {
    htmlTag: 'button',
    icon: {
      asset: 'Star',
    },
    text: 'Text',
  } as HeaderItemProps,
  dividers: {
    asset: 'VerticalBasic800',
  } as DividersProps,
  sendEmail: {
    htmlTag: 'button',
    icon: {
      asset: 'Star',
    },
    text: 'Text',
  } as HeaderItemProps,
};

export type HeaderItemsProps = {
  uploadHtml?: HeaderItemProps;
  dividers?: DividersProps;
  sendEmail?: HeaderItemProps;
  className?: string;
  sendTestModalProps?: SendTestModalProps;
};

const HeaderItems: React.FC<HeaderItemsProps> = ({
  uploadHtml,
  dividers,
  sendEmail,
  className,
  sendTestModalProps,
}) => {
  return (
    <div className={cx(styles.headerItems, className)}>
      <HeaderItem
        className={styles.uploadHtml}
        {...uploadHtml} />
      <Dividers
        className={styles.dividers}
        {...dividers} />
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <HeaderItem className={styles.sendEmail} {...sendEmail} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <SendTestModal {...sendTestModalProps} />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

HeaderItems.defaultProps = defaultProps;

export default HeaderItems;
