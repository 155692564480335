import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UseSchemaResult } from '../../../modules/schema/useSchema';
import { TemplateBlockProps } from './TemplateBlock';
import { BlockHeaderProps } from '../../molecules/BlockHeader';
import { CreateTemplatePayload } from '../../../modules/templates/api';
import { Template } from '../../../modules/templates/types';
import { ApiResponse } from '../../../lib/api/types';
import { AppDetails } from '../../../modules/auth/types';

export type TemplateBlockPresenterProps = {
  schemaState: UseSchemaResult;
  templateState: { loading: boolean; error?: Error; data: Template | null };
  createTemplate: (payload: CreateTemplatePayload) => Promise<ApiResponse<Template>>;
  appDetails: AppDetails | null;
};

const withPresenter = (
  View: React.FC<TemplateBlockProps>,
): React.FC<TemplateBlockPresenterProps> => {
  const Presenter: React.FC<TemplateBlockPresenterProps> = (props) => {
    const {
      schemaState, templateState, createTemplate, appDetails, ...otherProps
    } = props;
    const { pathname } = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const { loading: schemaLoading, error: schemaError, data: schemaProvider } = schemaState;
    const { loading: templateLoading, error: templateError, data: template } = templateState;

    useEffect(() => {
      // If the template has been created we can redirect
      if (template && !templateLoading) {
        history.push(`${pathname}/editor/${template.id}`);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template]);
    const handleButtonClick = async (): Promise<void> => {
      if (schemaProvider) {
        await createTemplate({ schemaProviderId: schemaProvider.id, name: 'Untitled', createdBy: `${appDetails?.account?.firstName} ${appDetails?.account?.lastName}`  });
      }
    };

    const blockHeader: BlockHeaderProps = {
      loading: templateLoading,
      title: schemaState.data?.name,
      button: {
        style: 'Brand',
        htmlTag: 'button',
        content: t('template_block.create_template'),
        onButtonClicked: handleButtonClick,
      },
    };

    return (
      <View
        {...otherProps}
        loading={schemaLoading}
        error={schemaError || templateError}
        blockHeader={blockHeader}
      />
    );
  };

  return Presenter;
};

export default withPresenter;
