import React from 'react';
import { defaultProps, TemplatesPageProps } from './TemplatesPage';
import { SchemaProvider } from '../../../modules/schema/types';
import { Template } from '../../../modules/templates/types'
import { useHistory, useParams } from 'react-router-dom';
import { TemplateCardProps } from '../../molecules/TemplateCard';
import { useTranslation } from 'react-i18next';
import { ApiResponse } from '../../../lib/api/types';

export type TemplatesPagePresenterProps = TemplatesPageProps & {
  schemas: SchemaProvider[] | null;
  templates: Template[] | null;
  refetchTemplates: () => void;
  deleteTemplate: (id: number) => Promise<ApiResponse<void>>;
};

const withPresenter = (
  View: React.FC<TemplatesPageProps>,
): React.FC<TemplatesPagePresenterProps> => {
  const Presenter: React.FC<TemplatesPagePresenterProps> = (props) => {
    const {
      schemas,
      templates,
      className,
      refetchTemplates,
      deleteTemplate
    } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const {
      appId, serviceName, schemaId
    } = useParams<{ appId: string, serviceName: string, schemaId: string }>();

    const handleDeleteTemplate = async (templateId: number): Promise<void> => {
      await deleteTemplate(templateId);
      refetchTemplates()
    };

    const templateList: TemplateCardProps[] = []

    templates?.map(signleTemplate => {
      if (signleTemplate.schemaProviderId === Number(schemaId)) {
        templateList.push({
          templateCardContent: {
            templateName: {
              serviceName: signleTemplate.name,
              icon: {
                asset: 'MoreVerticalSmall',
              },
              optionMenu: {
                options: [
                  {
                    name: t('template_page.options_menu.edit'),
                    onClick: () => history.push(`/${appId}/${serviceName}/${schemaId}/editor/${signleTemplate.id}`),
                  },
                  {
                    name: t('template_page.options_menu.delete'),
                    onClick: () => handleDeleteTemplate(Number(signleTemplate.id))
                  }
                ]
              }
            },
            label1: {
              label: t('template_page.template_card.created_by'),
              value: signleTemplate.createdBy,
            },
            label2: {
              label: t('template_page.template_card.created_on'),
              value: new Date(signleTemplate.createdAt).toDateString(),
            },
          }
        })
      } else {
        return
      }
    }
    )

    const templatesPageProps: TemplatesPageProps = {
      ...defaultProps,
      templateBlock: {
        templateCardList: {
          templateCards: templateList
        }
      }
    }
    return <View
      {...templatesPageProps}
      className={className} />;
  };
  return Presenter;
};
export default withPresenter;