import React from 'react';
import cx from 'classnames';

import styles from './Dividers.module.scss';

import HorizontalBasic300Asset from '../../../resources/icons/HorizontalBasic300.svg';
import VerticalBasic800Asset from '../../../resources/icons/VerticalBasic800.svg';

export type DividersAssetType = 'HorizontalBasic300' | 'VerticalBasic800' | '';

const defaultProps = {
  asset: 'VerticalBasic800' as DividersAssetType,
};

export type DividersProps = {
  asset?: DividersAssetType;
  dividerAlt?: string;
  className?: string;
};

const Dividers: React.FC<DividersProps> = ({
  asset,
  dividerAlt,
  className,
}) => {

  let divider = '';
  switch (asset) {
    case 'HorizontalBasic300':
      divider = HorizontalBasic300Asset;
      break;
    case 'VerticalBasic800':
      divider = VerticalBasic800Asset;
      break;
    default:
      divider = '';
      break;
  }

  return (
    <div className={cx(styles.dividers, className)}>
      <img
        className={styles.divider}
        alt={dividerAlt}
        src={divider} />
    </div>
  );
};

Dividers.defaultProps = defaultProps;

export default Dividers;
