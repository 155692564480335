import React from 'react';
import EditorLayoutView, { EditorLayoutProps } from './EditorLayout';
import withInteractor from './EditorLayout.interactor';
import withPresenter from './EditorLayout.presenter';
import EditorContextProvider from './context';

export type { EditorLayoutProps };

const EditorLayoutInteractor = withInteractor(withPresenter(EditorLayoutView));

const EditorLayout: React.FC<EditorLayoutProps> = (props) => {
  return (
    <EditorContextProvider>
      <EditorLayoutInteractor {...props} />
    </EditorContextProvider>
  );
};

export default EditorLayout;
