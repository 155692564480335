import React from 'react';
import cx from 'classnames';

import styles from './ButtonGroup.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';

const defaultProps = {
  primaryButton: {
    style: 'Brand',
    htmlTag: 'button',
    content: 'Brand',
  } as ButtonProps,
  secondaryButton: {
    style: 'Basic',
    htmlTag: 'button',
    content: 'Basic',
  } as ButtonProps,
};

export type ButtonGroupProps = {
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  className?: string;
};

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  primaryButton,
  secondaryButton,
  className,
}) => {
  return (
    <div className={cx(styles.buttonGroup, className)}>
      <Button
        className={styles.primaryButton}
        {...primaryButton} />
      <Button
        className={styles.secondaryButton}
        {...secondaryButton} />
    </div>
  );
};

ButtonGroup.defaultProps = defaultProps;

export default ButtonGroup;
