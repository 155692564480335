import React from 'react';
import cx from 'classnames';

import styles from './NestedMenuItem.module.scss';

import MenuItemBody, { MenuItemBodyProps } from '../../atoms/MenuItemBody';

const defaultProps = {
  menuItemBody: {
    state: 'Collapsed',
    htmlTag: 'button',
    icon: {
      asset: 'Star',
    },
    value: 'List item',
    expandIcon: {
      asset: 'ArrowDown',
    },
  } as MenuItemBodyProps,
};

export type NestedMenuItemProps = {
  menuItemBody?: MenuItemBodyProps;
  className?: string;
};

const NestedMenuItem: React.FC<NestedMenuItemProps> = ({
  menuItemBody,
  className,
}) => {
  return (
    <div className={cx(styles.nestedMenuItem, className)}>
      <MenuItemBody
        className={styles.menuItemBody}
        {...menuItemBody} />
    </div>
  );
};

NestedMenuItem.defaultProps = defaultProps;

export default NestedMenuItem;
