import React from 'react';
import cx from 'classnames';
import styles from './LeftNav.module.scss';
import AppInfo, { AppInfoProps } from '../../molecules/AppInfo';
import MenuItemList, { MenuItemListProps } from '../MenuItemList';

export type LeftNavProps = {
  appInfo?: AppInfoProps;
  menuItemList?: MenuItemListProps;
  className?: string;
};

const LeftNav: React.FC<LeftNavProps> = ({
  appInfo,
  menuItemList,
  className,
}) => {
  
  return (
    <div className={cx(styles.leftNav, className)}>
      <AppInfo
        className={styles.appInfo}
        {...appInfo} />
      <MenuItemList
        className={styles.menuItemList}
        {...menuItemList} />
    </div>
  );
};

export default LeftNav;
