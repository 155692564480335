import React from 'react';
import cx from 'classnames';

import styles from './MenuItemList.module.scss';

import MenuItem, { MenuItemProps } from '../../molecules/MenuItem';

const defaultProps = {
  menuItems: [
    {
      state: 'Collapsed',
      menuItemBody: {
        state: 'Collapsed',
        htmlTag: 'button',
        icon: {
          asset: 'Star',
        },
        value: {
          value: 'List Item'
        },
        expandIcon: {
          asset: 'ArrowDown',
        },
      },
    },
    {
      state: 'Collapsed',
      menuItemBody: {
        state: 'Collapsed',
        htmlTag: 'button',
        icon: {
          asset: 'Star',
        },
        value: {
          value: 'List Item'
        },
        expandIcon: {
          asset: 'ArrowDown',
        },
      },
    },
    {
      state: 'Expanded',
      menuItemBody: {
        state: 'Expanded',
        htmlTag: 'button',
        icon: {
          asset: 'Star',
        },
        value: {
          value: 'List Item'
        },
        expandIcon: {
          asset: 'ArrowUp',
        },
      },
      nestedMenuItemList: {
        nestedMenuItems: [
          {
            menuItemBody: {
              state: 'Collapsed',
              htmlTag: 'button',
              icon: {
                asset: 'Star',
              },
              value: {
                value: 'List item',
              },
              expandIcon: {
                asset: 'ArrowDown',
              },
            },
          },
          {
            menuItemBody: {
              state: 'Collapsed',
              htmlTag: 'button',
              icon: {
                asset: 'Star',
              },
              value: {
                value: 'List item',
              },
              expandIcon: {
                asset: 'ArrowDown',
              },
            },
          },
          {
            menuItemBody: {
              state: 'Collapsed',
              htmlTag: 'button',
              icon: {
                asset: 'Star',
              },
              value: {
                value: 'List item',
              },
              expandIcon: {
                asset: 'ArrowDown',
              },
            },
          },
        ],
      },
    },
  ] as MenuItemProps[],
};

export type MenuItemListProps = {
  menuItems?: MenuItemProps[];
  className?: string;
};

const MenuItemList: React.FC<MenuItemListProps> = ({
  menuItems,
  className,
}) => {
  const menuItemArray = menuItems?.map((menuItem) => (
    <MenuItem
      className={styles.menuItem}
      {...menuItem} />
  ));

  return (
    <div className={cx(styles.menuItemList, className)}>
      {menuItemArray}
    </div>
  );
};

MenuItemList.defaultProps = defaultProps;

export default MenuItemList;
