import React, { useContext } from 'react';
import { TemplatesPageProps } from './TemplatesPage';
import { TemplatesPagePresenterProps } from './TemplatesPage.presenter';
import { TemplateContext } from '../../../modules/templates/TemplateContext';
import { SchemaContext } from '../../../modules/schema/SchemaContext';
import useDeleteTemplate from '../../../modules/templates/useDeleteTemplate';
const withInteractor = (
  Presenter: React.FC<TemplatesPagePresenterProps>,
): React.FC<TemplatesPageProps> => {

  const Interactor: React.FC<TemplatesPageProps> = (props) => {
    const { templateState } = useContext(TemplateContext)
    const { schemaState } = useContext(SchemaContext)
    const [
      { loading: deleteLoading, error: deleteError }, deletetemplateById,
    ] = useDeleteTemplate();
    return (
      <Presenter
        {...props}
        schemas={schemaState.data}
        templates={templateState.data}
        refetchTemplates={templateState.refetch}
        deleteTemplate={deletetemplateById}
      />
    );
  }
  return Interactor;
};
export default withInteractor;
