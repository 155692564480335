import React from 'react';
import cx from 'classnames';

import styles from './ImageContainers.module.scss';

export type ImageContainersStyleType = '40px' | '64px' | '3x2';

const defaultProps = {
  style: '3x2' as ImageContainersStyleType,
};

export type ImageContainersProps = {
  style?: ImageContainersStyleType;
  className?: string;
};

const ImageContainers: React.FC<ImageContainersProps> = ({
  style,
  className,
}) => {

  let currentStyle = '';
  switch (style) {
    case '40px':
      currentStyle = styles.imageContainers40px;
      break;
    case '64px':
      currentStyle = styles.imageContainers64px;
      break;
    case '3x2':
      currentStyle = styles.imageContainers3x2;
      break;
    default:
      break;
  }

  return (
    <div className={cx(currentStyle, className)}/>
  );
};

ImageContainers.defaultProps = defaultProps;

export default ImageContainers;
