import { useCallback } from 'react';
import useGet from '../../lib/api/useGet';
import { getTemplateList } from './api';
import { Template, UseTemplateResult } from './types';

const useTemplateList = (): UseTemplateResult => {
  const get = useCallback((): Promise<Template[]> => getTemplateList(),[]);
  return useGet(get)
};

export default useTemplateList;
