import React, { useContext } from 'react';
import useTestEmail from '../../../modules/email/useTestEmail';
import { EditorContext } from './context';
import { EditorLayoutProps } from './EditorLayout';
import { EditorLayoutPresenterProps } from './EditorLayout.presenter';

const withInteractor = (
  Presenter: React.FC<EditorLayoutPresenterProps>,
): React.FC<EditorLayoutProps> => {
  const Interactor: React.FC<EditorLayoutProps> = (props) => {
    const context = useContext(EditorContext);
    const testEmail = useTestEmail();
    return <Presenter {...props} context={context} testEmail={testEmail} />;
  };

  return Interactor;
};

export default withInteractor;
