import React from 'react';
import cx from 'classnames';

import styles from './MenuItemBody.module.scss';

import Icon, { IconProps } from '../Icon';
import LeftBorder, { LeftBorderProps } from '../LeftBorder';

export type MenuItemBodyStateType = 'Collapsed' | 'Expanded';
export type MenuItemBodyHtmlTagType = 'button';
export type MenuItemBodyMenuItemBodyTypeType = 'button' | 'submit' | 'reset';

export type TextProps = {
  value?: string,
  className?: string;
  onClick?: () => void;
};
const defaultProps = {
  state: 'Expanded' as MenuItemBodyStateType,
  htmlTag: 'button' as MenuItemBodyHtmlTagType,
  icon: {
    asset: 'Star',
  } as IconProps,
  value: {
    value: 'List item',
  },
  expandIcon: {
    asset: 'ArrowUp',
  } as IconProps,
};

export type MenuItemBodyProps = {
  state?: MenuItemBodyStateType;
  htmlTag?: MenuItemBodyHtmlTagType;
  menuItemBodyType?: MenuItemBodyMenuItemBodyTypeType;
  onMenuItemBodyClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: IconProps;
  value?: TextProps;
  expandIcon?: IconProps;
  className?: string;
  leftBorder?: LeftBorderProps;
};

const MenuItemBody: React.FC<MenuItemBodyProps> = ({
  state,
  htmlTag,
  menuItemBodyType,
  onMenuItemBodyClicked,
  icon,
  value,
  expandIcon,
  className,
  leftBorder,
}) => {

  const contentView = (
    <div className={styles.content}>
      <Icon
        onClick={value?.onClick}
        className={styles.icon}
        {...icon} />
      <p onClick={value?.onClick} className={styles.value}>
        {value?.value}
      </p>
      <Icon
        className={styles.expandIcon}
        {...expandIcon} />
    </div>
  );

  let leftBorderView;

  switch (state) {
    case 'Collapsed':
      leftBorderView = (
        <LeftBorder
          className={styles.leftBorder}
          {...leftBorder} />
      );
      break;
    case 'Expanded':
      leftBorderView = (
        <LeftBorder
          className={styles.leftBorder}
          {...leftBorder} />
      );
      break;
  }

  return (
    <button
      type={menuItemBodyType}
      onClick={onMenuItemBodyClicked}
      className={cx(styles.menuItemBody, className)}>
      {leftBorderView}
      {contentView}
    </button>
  );
};

MenuItemBody.defaultProps = defaultProps;

export default MenuItemBody;
