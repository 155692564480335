import React from 'react';
import cx from 'classnames';

import styles from './NestedMenuItemList.module.scss';

import NestedMenuItem, { NestedMenuItemProps } from '../NestedMenuItem';

const defaultProps = {
  nestedMenuItems: [
      {
        menuItemBody: {
          state: 'Collapsed',
          htmlTag: 'button',
          icon: {
            asset: 'Star',
          },
          value: 'List item',
          expandIcon: {
            asset: 'ArrowDown',
          },
        },
      },
      {
        menuItemBody: {
          state: 'Collapsed',
          htmlTag: 'button',
          icon: {
            asset: 'Star',
          },
          value: 'List item',
          expandIcon: {
            asset: 'ArrowDown',
          },
        },
      },
      {
        menuItemBody: {
          state: 'Collapsed',
          htmlTag: 'button',
          icon: {
            asset: 'Star',
          },
          value: 'List item',
          expandIcon: {
            asset: 'ArrowDown',
          },
        },
      },
  ] as NestedMenuItemProps[],
};

export type NestedMenuItemListProps = {
  nestedMenuItems?: NestedMenuItemProps[];
  className?: string;
};

const NestedMenuItemList: React.FC<NestedMenuItemListProps> = ({
  nestedMenuItems,
  className,
}) => {
  const nestedMenuItemArray = nestedMenuItems?.map((nestedMenuItem) => (
    <NestedMenuItem
      className={styles.nestedMenuItem}
      {...nestedMenuItem} />
  ));
  
  return (
    <div className={cx(styles.nestedMenuItemList, className)}>
      {nestedMenuItemArray}
    </div>
  );
};

NestedMenuItemList.defaultProps = defaultProps;

export default NestedMenuItemList;
