import { useCallback } from 'react';
import useGet from '../../lib/api/useGet';
import useUpdate from '../../lib/api/useUpdate';
import { getTemplate, updateTemplate } from './api';
import { Template } from './types';

export type UseTemplateResult = [
  {
    loading: boolean;
    error: undefined | Error;
    data: Template | null;
    refetch: () => void;
  },
  (payload: Template) => Promise<void>
];

const useTemplate = (id: number | string): UseTemplateResult => {
  const get = useCallback((): Promise<Template> => getTemplate(id), [id]);
  const {
    loading: getLoading,
    error: getError,
    data,
    refetch,
  } = useGet(get);

  const [
    { loading: updateLoading, error: updateError },
    update,
  ] = useUpdate<Template>((payload: Template) => updateTemplate(Number(id), payload));

  const handleUpdate = async (payload: Template): Promise<void> => {
    await update(payload);
    refetch();
  };

  return [
    {
      data, loading: getLoading || updateLoading, error: getError || updateError, refetch,
    },
    handleUpdate,
  ];
};

export default useTemplate;
