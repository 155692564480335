import { ApiResponse } from '../../lib/api/types';
import usePost from '../../lib/api/usePost';
import { createTemplate, CreateTemplatePayload } from './api';
import { Template } from './types';

export type UseCreateTemplateResult = [
  {
    loading: boolean;
    error: undefined | Error;
    data: Template | null;
  },
  (payload: CreateTemplatePayload) => Promise<ApiResponse<Template>>
];

const useCreateTemplate = (): UseCreateTemplateResult => {
  const handleCreate = (
    payload: CreateTemplatePayload,
  ): Promise<Template> => createTemplate(payload);
  const [
    state,
    create,
  ] = usePost<Template, CreateTemplatePayload>(handleCreate);

  return [
    state,
    create,
  ];
};

export default useCreateTemplate;
