import React, { useRef } from 'react';
import cx from 'classnames';

import styles from './TagItem.module.scss';

import Icon, { IconProps } from '../../atoms/Icon';

const defaultProps = {
  value: '{{tagname}}',
  icon: {
    asset: 'Copy',
  } as IconProps,
};

export type TagItemProps = {
  value?: string;
  icon?: IconProps;
  className?: string;
};

const TagItem: React.FC<TagItemProps> = ({
  value,
  icon,
  className,
}) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const handleCopy = (): void => {
    if (ref?.current) {
      ref.current.select();
      document.execCommand('copy');
    }
  };

  return (
    <div className={cx(styles.tagItem, className)}>
      <input 
        ref={ref} 
        className={styles.value} 
        value={value} 
        readOnly />
      <Icon
        className={styles.icon}
        onClick={handleCopy}
        {...icon} />
    </div>
  );
};

TagItem.defaultProps = defaultProps;

export default TagItem;
