import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  RouteProps,
  Route,
} from 'react-router-dom';

import styles from './App.module.scss';

import {
  ADMIN_URL, ADMIN_LOGIN_URL, IS_DEV,
} from './lib/config';

import MainLayout from './components/layouts/MainLayout';
import EditorLayout from './components/layouts/EditorLayout';
import { AuthContext } from './modules/auth';
import { ApiError } from './lib/api/types';
import { redirectToLogin } from './modules/auth/utils';

const InvalidRoute: React.FC<RouteProps> = () => {
  // for local development fallback to default values.
  if (IS_DEV) {
    return <Redirect to="/1/communication" />;
  }

  window.location.assign(ADMIN_URL);
  return <div>redirecting</div>;
};

const CheckUserAccess: React.FC<RouteProps> = (props) => {
  const { appDetailsState } = useContext(AuthContext);
  const { loading, error, data} = appDetailsState;

  useEffect(() => {
    if(error && error instanceof ApiError) {
      if(error.code === 401) {
        redirectToLogin();
      }
    }
  }, [error]);

  if (loading) {
    return <div>loading...</div>;
  }

  if (error || !data) {
    return <div/>;
  }

  // if no errors then proceed to the route
  return <Route {...props} />;
};

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  // for local development we don't check admin user session.
  if (IS_DEV) {
    return <Route {...props} />;
  }
  return <CheckUserAccess {...props}/>;
};

const App: React.FC<{}> = () => {
  const props: any = {
    className: styles.layout,
  };

  return (
    <Router>
      <Switch>
        <ProtectedRoute path="/:appId/:serviceName/:schemaId/editor/:templateId">
          <EditorLayout {...props}/>
        </ProtectedRoute>
        <ProtectedRoute path="/:appId/:serviceName">
          <MainLayout {...props}/>
        </ProtectedRoute>
        <InvalidRoute path="/" />
      </Switch>
    </Router>
  );
};
export default App;
