import React from 'react';
import cx from 'classnames';

import styles from './ServiceBlock.module.scss';

import ServiceCardList, { ServiceCardListProps } from '../../organisms/ServiceCardList';

const defaultProps = {
  title: 'Title',
  serviceCardList: {
    serviceCards: [
        {
          imageContainers: {
            style: '64px',
          },
          serviceName: 'Service Name',
          templatesCount: 'No of templates',
          tag: {
            value: 'Value',
          },
          button: {
            style: 'Brand',
            htmlTag: 'button',
            content: 'Brand',
          },
        },
        {
          imageContainers: {
            style: '64px',
          },
          serviceName: 'Service Name',
          templatesCount: 'No of templates',
          tag: {
            value: 'Value',
          },
          button: {
            style: 'Brand',
            htmlTag: 'button',
            content: 'Brand',
          },
        },
        {
          imageContainers: {
            style: '64px',
          },
          serviceName: 'Service Name',
          templatesCount: 'No of templates',
          tag: {
            value: 'Value',
          },
          button: {
            style: 'Brand',
            htmlTag: 'button',
            content: 'Brand',
          },
        },
    ],
  } as ServiceCardListProps,
};

export type ServiceBlockProps = {
  title?: string;
  serviceCardList?: ServiceCardListProps;
  className?: string;
};

const ServiceBlock: React.FC<ServiceBlockProps> = ({
  title,
  serviceCardList,
  className,
}) => {
  return (
    <div className={cx(styles.serviceBlock, className)}>
      <p className={styles.title}>
        {title}
      </p>
      <ServiceCardList
        className={styles.serviceCardList}
        {...serviceCardList} />
    </div>
  );
};

ServiceBlock.defaultProps = defaultProps;

export default ServiceBlock;
