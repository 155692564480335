import React from 'react';

type CustomToggleProps = {
  onClick: (e: React.MouseEvent) => void;
};

const CustomToggle = React.forwardRef<HTMLDivElement, CustomToggleProps>((
  props, ref,
) => {
  const { onClick, children } = props;
  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    onClick(e);
  };
  return (
    <div ref={ref} onClick={handleClick}>
      {children}
    </div>
  );
});

CustomToggle.displayName = 'CustomToggle';

export default CustomToggle;
