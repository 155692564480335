import React from 'react';
import { defaultProps, DesignLibraryProps } from './DesignLibrary';
import { SchemaProvider } from '../../../modules/schema/types';
import { Template } from '../../../modules/templates/types'
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { defaultProps as ButtonDefaultProps } from '../../atoms/Button/Button'
export type DesignLibraryPagePresenterProps = DesignLibraryProps & {
  schemas: SchemaProvider[] | null;
  templates: Template[] | null
};

const withPresenter = (
  View: React.FC<DesignLibraryProps>,
): React.FC<DesignLibraryPagePresenterProps> => {
  const Presenter: React.FC<DesignLibraryPagePresenterProps> = (props) => {
    const {
      schemas,
      templates,
      className,
    } = props;
    const { pathname } = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    
    const schemaList = schemas?.map(item => {            
      const templateCount = templates?.filter(
        emailTemplate => emailTemplate.schemaProviderId == item.id)
      return {
        ...item.schema,
        serviceName:item.name,
        templatesCount:`${String(templateCount?.length)} ${t('design_library_page.service_card.email_templates')}`,        
        tag: {
          value: item.schema.serviceName
        },
        button: {
          ...ButtonDefaultProps,
          content: t('design_library_page.service_card.view_templates'),
          onButtonClicked: () => history.push(`${pathname}/${item.id}`)
        }
      }
    })
    const designLibraryPageProps: DesignLibraryProps = {
      ...defaultProps,
      serviceBlock: {
        title: t('design_library_page.title'),
        serviceCardList: {
          ...defaultProps.serviceBlock.serviceCardList,
          serviceCards: schemaList ? schemaList : []
        }
      }
    }
    return <View
      {...designLibraryPageProps}
      className={className} />;
  };
  return Presenter;
};
export default withPresenter;