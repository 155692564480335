import React from 'react';

import classes from './FilePicker.module.scss';

export type FilePickerProps = {
  fileInputRef: React.RefObject<HTMLInputElement>;
  onFileSelected: (content: string) => void;
}

const FilePicker: React.FC<FilePickerProps> = ({ fileInputRef, onFileSelected }) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        if (typeof e.target?.result === 'string') {
          onFileSelected(e.target.result);
        }
      };
    }
  };

  return (
    <input
      className={classes.hide}
      type="file"
      accept=".html"
      onChange={handleFileChange}
      ref={fileInputRef}
    />
  )
};

export default FilePicker;
