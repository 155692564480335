import React from 'react';
import cx from 'classnames';

import styles from './BlockHeader.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';

const defaultProps = {
  loading: false,
  title: 'Title',
  button: {
    style: 'Brand',
    htmlTag: 'button',
    content: 'Brand',
  } as ButtonProps,
};

export type BlockHeaderProps = {
  loading?: boolean;
  title?: string;
  button?: ButtonProps;
  className?: string;
};

const BlockHeader: React.FC<BlockHeaderProps> = ({
  loading,
  title,
  button,
  className,
}) => {
  return (
    <div className={cx(styles.blockHeader, className)}>
      <p className={styles.title}>
        {title}
      </p>
      <Button
        className={styles.button}
        {...button}
        loading={loading} />
    </div>
  );
};

BlockHeader.defaultProps = defaultProps;

export default BlockHeader;
