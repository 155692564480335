import React from 'react';
import cx from 'classnames';

import styles from './HeaderItem.module.scss';

import Icon, { IconProps } from '../Icon';

export type HeaderItemHtmlTagType = 'button';
export type HeaderItemHeaderItemTypeType = 'button' | 'submit' | 'reset';

const defaultProps = {
  htmlTag: 'button' as HeaderItemHtmlTagType,
  icon: {
    asset: 'Star',
  } as IconProps,
  text: 'Text',
};

export type HeaderItemProps = {
  htmlTag?: HeaderItemHtmlTagType;
  headerItemType?: HeaderItemHeaderItemTypeType;
  onHeaderItemClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: IconProps;
  text?: string;
  className?: string;
};

const HeaderItem: React.FC<HeaderItemProps> = ({
  htmlTag,
  headerItemType,
  onHeaderItemClicked,
  icon,
  text,
  className,
}) => {
  return (
    <button
      type={headerItemType}
      onClick={onHeaderItemClicked}
      className={cx(styles.headerItem, className)}>
      <Icon
        className={styles.icon}
        {...icon} />
      <p className={styles.text}>
        {text}
      </p>
    </button>
  );
};

HeaderItem.defaultProps = defaultProps;

export default HeaderItem;
