import React from 'react';
import cx from 'classnames';

import styles from './SendTestModal.module.scss';

import TextField, { TextFieldProps } from '../../molecules/TextField';
import Button, { ButtonProps } from '../../atoms/Button';
import Notch from '../../../resources/icons/Notch.svg'
const defaultProps = {
  title: 'Send test email to',
  textField: {
    textBox: {
      style: 'Placeholder',
      htmlTag: 'input',
      textBoxPlaceholder: ' ',
      error: undefined,
    }
  } as TextFieldProps,
  button: {
    style: 'Brand',
    htmlTag: 'button',
    content: 'Brand',
  } as ButtonProps,
  sendTestEmail: () => { },
};

export type SendTestModalProps = {
  title?: string;
  textField?: TextFieldProps;
  button?: ButtonProps;
  className?: string;
  sendTestEmail?: (receiver: string) => void;
};

const SendTestModal: React.FC<SendTestModalProps> = ({
  title,
  textField,
  button,
  className,
}) => {
  return (
    <div className={cx(styles.sendTestModal, className)}>
      <img src={Notch} className={styles.notch}/>
      <div className={styles.content}>
        <p className={styles.title}>
          {title}
        </p>
        <TextField
          {...textField} />
        <Button
          className={styles.button}
          {...button} />
      </div>
    </div>
  );
};

SendTestModal.defaultProps = defaultProps;

export default SendTestModal;
