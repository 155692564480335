import React from 'react';
import cx from 'classnames';

import styles from './CodeEditorSubheader.module.scss';

const defaultProps = {
  templateName: 'Template name',
};

export type CodeEditorSubheaderProps = {
  templateName?: string;
  className?: string;
};

const CodeEditorSubheader: React.FC<CodeEditorSubheaderProps> = ({
  templateName,
  className,
}) => {
  return (
    <div className={cx(styles.codeEditorSubheader, className)}>
      <p className={styles.templateName}>
        {templateName}
      </p>
    </div>
  );
};

CodeEditorSubheader.defaultProps = defaultProps;

export default CodeEditorSubheader;
