import React from 'react';
import cx from 'classnames';

import styles from './LeftBorder.module.scss';

const defaultProps = {
};

export type LeftBorderProps = {
  className?: string;
};

const LeftBorder: React.FC<LeftBorderProps> = ({
  className,
}) => {
  return (
    <div className={cx(styles.leftBorder, className)}>
      <div className={styles.path}/>
    </div>
  );
};

LeftBorder.defaultProps = defaultProps;

export default LeftBorder;
