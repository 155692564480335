import React, { useContext } from 'react';
import { LeftNavProps } from './LeftNav';
import { LeftNavOrganismsPresenterProps } from './LeftNav.presenter';
import { SchemaContext } from '../../../modules/schema/SchemaContext';
import { AuthContext } from '../../../modules/auth/AuthContext'
import { TemplateContext } from '../../../modules/templates/TemplateContext'
const withInteractor = (
  Presenter: React.FC<LeftNavOrganismsPresenterProps>,
): React.FC<LeftNavProps> => {

  const Interactor: React.FC<LeftNavProps> = (props) => {
    const { schemaState } = useContext(SchemaContext)    
    const {templateState} = useContext(TemplateContext)    
    const { appDetailsState: { data } } = useContext(AuthContext)

    return (
      <Presenter
        schemas={schemaState.data}
        appDetails={data}
        templates= {templateState.data}
        {...props}
      />
    );
  }
  return Interactor;
};
export default withInteractor;
