import axios from 'axios';
import i18next from 'i18next';
import { ServiceErrorResponse } from '../../lib/api/types';
import { getServerUrl, toApiError } from '../../lib/api/utils';
import { SchemaProvider } from './types';

const GET_SCHEMA_ERROR_MESSAGE = i18next.t('error.schema.default_get');

// eslint-disable-next-line import/prefer-default-export
export const getSchema = async (id: number | string): Promise<SchemaProvider> => {
  try {
    const { data } = await axios.get<SchemaProvider>(
      `${getServerUrl()}/schemas/${id}`, { withCredentials: true });
    return data
  } catch (error) {
    throw toApiError(error);
  }
};
export const getSchemaList = async (): Promise<SchemaProvider[]> => {
  try {
    const { data } = await axios.get<SchemaProvider[]>(
      `${getServerUrl()}/schemas`, { withCredentials: true });
    return data
  } catch (error) {
    throw toApiError(error);
  }
};
