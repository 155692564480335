import { ApiResponse } from '../../lib/api/types';
import usePost from '../../lib/api/usePost';
import { sendTestEmail, TestEmailPayload } from './api';

export type UseTestEmailResult = [
  {
    loading: boolean;
    error: undefined | Error;
  },
  (payload: TestEmailPayload) => Promise<ApiResponse<void>>
];

const useTestEmail = (): UseTestEmailResult => {
  const [
    { error, loading },
    sendEmail,
  ] = usePost<void, TestEmailPayload>(sendTestEmail);

  return [
    { error, loading },
    sendEmail,
  ];
};

export default useTestEmail;
