import React from 'react';
import { StylesProvider } from '@material-ui/core/styles';

import { LocaleProvider } from './modules/locale';
import { AuthProvider } from './modules/auth';

const AppProvider: React.FC<{}> = ({ children }) => (
  <LocaleProvider>
    <AuthProvider>
      <StylesProvider injectFirst>
        {children}
      </StylesProvider>
    </AuthProvider>
  </LocaleProvider>
);

export default AppProvider;
